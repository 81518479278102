/* eslint-disable */
import React, { Component, Fragment } from 'react'
//redux
import { connect } from 'react-redux'
import { adActions } from '../redux/models/ad'
//路由
import { BrowserRouter, Switch, Route, Router, Redirect } from 'react-router-dom'
import { createBrowserHistory } from 'history'
//页面
import Home from '../pages/Home/Home'
import Product from '../pages/Product/Product'
import SaaS from '../pages/SaaS/SaaS'
import Newretail from '../pages/Newretail/Newretail'
import Lexin from '../pages/Lexin/Lexin'
import Aboutus from '../pages/Aboutus/Aboutus'
import JoinUs from '../pages/JoinUs/JoinUs'
import Qualification from '../pages/Qualification/Qualification'
//组件
import JianLiu from '../pages/jianliu/jianliu'
import Header from '../components/Header/Header'
import Footer from '../components/Footer/Footer'
import Programme from '../pages/Programme/Programme'
import Engineering from '../pages/Engineering/Engineering'
import Digitization from '../pages/Digitization/Digitization'
import Costcontrol from '../pages/Costcontrol/Costcontrol'
import Contract from '../pages/Contract/Contract'
import ServiceIndustry from '../pages/ServiceIndustry/ServiceIndustry'
import QRcode from '../pages/QRcode/QRcode'
import SupplyChain from '../pages/SupplyChain/SupplyChain'
import HomeFurnishing from '../pages/HomeFurnishing/HomeFurnishing'
import Document from '../pages/Document/Document'
import JinRong from '../pages/TabItem2/JinRong/JinRong'
import JiaoYu from '../pages/TabItem2/JiaoYu/JiaoYu'
import GongXiang from '../pages/TabItem2/GongXiang/GongXiang'
import ZhiBo from '../pages/TabItem2/ZhiBo/ZhiBo'
import ZiXun from '../pages/TabItem2/ZiXun/ZiXun'
import Duan from '../pages/TabItem2/Duan/Duan'
import FangDiChan from '../pages/TabItem2/FangDiChan/FangDiChan'
import ZhiXiao from '../pages/TabItem2/ZhiXiao/ZhiXiao'
import SheJiao from '../pages/TabItem2/SheJiao/SheJiao'
import Community from '../pages/Community/Community'
import Purchase from '../pages/Purchase/Purchase'
const browserHistory = createBrowserHistory()
class BasicLayout extends Component {
  //周期周期周期周期周期周期周期周期周期周期周期周期周期周期周期周期周期周期周期
  componentWillMount() {
    if (localStorage.getItem('bldweb') && localStorage.getItem('bldweb') !== '') {
      const { dispatch } = this.props
      dispatch(adActions.loginAgain(localStorage.getItem('bldweb')))
    }
  }
  componentWillUnmount() {
    localStorage.setItem('bldweb', '')
  }
  //渲染渲染渲染渲染渲染渲染渲染渲染渲染渲染渲染渲染渲染渲染渲染渲染渲染渲染渲染
  render() {
    return (
      <Fragment>
        <div style={{ width: '100%', height: '74px' }}></div>
        <Header />
        {/* <Router   history={browserHistory}> */}
        <Switch>
          <Route component={Home} exact path="/" />
          {/* 产品案例 */}
          <Route path="/product" component={Product} />
          {/* 通信新零售 */}
          <Route path="/newretail" component={Newretail} />
          {/* 灵活用工 */}
          <Route path="/lexin" component={Lexin} />
          {/* SaaS */}
          <Route path="/SaaS" component={SaaS} />
          <Route path="/jianliu" component={JianLiu} />
          {/* 关于我们 */}
          <Route path="/aboutus" component={Aboutus} />
          {/* 加入我们 */}
          <Route path="/joinus" component={JoinUs} />
          {/* 企业资质 */}
          <Route path="/qualification" component={Qualification} />
          {/* 解决方案 */}
          <Route path="/programme" component={Programme} />
          {/* 工程管理 */}
          <Route path="/engineering" component={Engineering} />
          {/* 数智化CRM系统 */}
          <Route path="/digitization" component={Digitization} />
          {/* 数字化进销存 */}
          <Route path="/Purchase" component={Purchase} />

          {/* 费控报销 */}
          <Route path="/costcontrol" component={Costcontrol} />
          {/* 合同管理 */}
          <Route path="/contract" component={Contract} />
          {/* 服务业项目管理系统 */}
          <Route path="/serviceIndustry" component={ServiceIndustry} />
          {/* 二维码扫码评价系统 */}
          <Route path="/QRcode" component={QRcode} />
          {/* 供应链协同管理系统 */}
          <Route path="/SupplyChain" component={SupplyChain} />
          {/* 家居门店数字化管理 */}
          <Route path="/HomeFurnishing" component={HomeFurnishing} />
          {/* 公文管理 */}
          <Route path="/Document" component={Document} />
          {/* 金融行业解决方案 */}
          <Route path="/JinRong" component={JinRong} />
          {/* 教育行业解决方案 */}
          <Route path="/JiaoYu" component={JiaoYu} />
          {/* 共享经济平台解决方案 */}
          <Route path="/GongXiang" component={GongXiang} />

          {/* 直播行业解决方案 */}
          <Route path="/ZhiBo" component={ZhiBo} />
          {/* 短视频行业解决方案 */}
          <Route path="/Duan" component={Duan} />
          {/* 咨询行业解决方案 */}
          <Route path="/ZiXun" component={ZiXun} />
          {/* 直销行业解决方案 */}
          <Route path="/ZhiXiao" component={ZhiXiao} />
          {/* 房地产行业解决方案 */}
          <Route path="/FangDiChan" component={FangDiChan} />
          {/* 社交电商解决方案 */}
          <Route path="/SheJiao" component={SheJiao} />

          {/* 数字化街道社区工作平台 */}
          <Route path="/Community" component={Community} />
          <Redirect to="/" />
        </Switch>
        {/* </Router> */}
        <Footer />
      </Fragment>
    )
  }
}

// export default BasicLayout

const mapStateToProps = (state) => ({
  //选择注入哪些state，state 所有的state(reducer)
  ad: state.ad,
})

export default connect(mapStateToProps)(BasicLayout)
