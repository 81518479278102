/* eslint-disable */
import React, { Component } from 'react'
import $ from 'jquery'
import { Row, Col, Input, Button, message } from 'antd'
import PageBg from '../../components/PageBg2/PageBg'
import './Community.less'
import './base.css'
import requestw from '../../utils/requestw'

export default class Community extends Component {
  constructor(props) {
    super(props)
    this.state = {
      isloading: false,
    }
  }
  //周期周期周期周期周期周期周期周期周期周期周期周期周期周期周期周期周期周期周期周期周期周期周期周期周期周期周期周期
  componentDidMount() {
    // if ((navigator.userAgent.match(/(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i))) {
    //   window.location.href = 'http://mjianliu.bld365.com/'
    // }
    window.scrollTo(0, 0)
    this.runJq()
  }
  runJq() {
    $(function () {
      //导航菜单
      var ytx = {}
      $('[t_nav]').hover(
        function () {
          var _nav = $(this).attr('t_nav')
          clearTimeout(ytx[_nav + '_timer'])
          ytx[_nav + '_timer'] = setTimeout(function () {
            $('#' + _nav)
              .stop(true, true)
              .slideDown(200)
          }, 150)
        },
        function () {
          var _nav = $(this).attr('t_nav')
          clearTimeout(ytx[_nav + '_timer'])
          ytx[_nav + '_timer'] = setTimeout(function () {
            $('#' + _nav)
              .stop(true, true)
              .slideUp(200)
          }, 150)
        }
      )

      //导航绿色标题高度处理
      $('.submenu dl').each(function () {
        var dl_h = $(this).height()
        $(this).find('dt').height(dl_h)
      })

      //首页短信、im、语音、视频动画效果
      $('.box1 ul li').hover(
        function () {
          $(this).find('.unhover').find('i').animate({ bottom: -145, opacity: '0' }, 500)
          $(this).find('.unhover').find('.txt').animate({ left: -125, opacity: '0' }, 500)
          $(this).find('.hover').show().find('i').animate({ top: 0 }, 500)
          $(this).find('.hover').show().find('.txt').animate({ right: 0 }, 500)
        },
        function () {
          $(this).find('.unhover').find('i').animate({ bottom: 0, opacity: '1' }, 500)
          $(this).find('.unhover').find('.txt').animate({ left: 0, opacity: '1' }, 500)
          $(this).find('.hover').find('i').animate({ top: -125 }, 500)
          $(this).find('.hover').find('.txt').animate({ right: -110 }, 500)
        }
      )

      //首页“简单”、“可靠”、“专注”、“全球”动画效果
      var current = $('.index_2 span.txt.current').index()
      $('.index_2 span.txt')
        .not('.current')
        .hover(
          function () {
            var span_index = $(this).index()
            $(this).addClass('current')
            $('.txt_desc').find('span').eq(span_index).fadeIn(800).siblings('span').fadeOut(800)
          },
          function () {
            var span_index = $(this).index()
            $(this).removeClass('current')
            $('.txt_desc').find('span').eq(span_index).fadeOut(800).siblings('span').eq(current).fadeIn(800)
          }
        )

      //首页客户图标鼠标放上去状态变化
      $('.index_4 ul li').hover(
        function () {
          var img_src = $(this).find('img').attr('src')
          var img_name = img_src.substring(7).replace('.png', '')
          //alert(img_name);
          $(this)
            .find('img')
            .attr('src', '/front/' + img_name + '_hover.png')
        },
        function () {
          var img_src = $(this).find('img').attr('src')
          var img_name = img_src.substring(7).replace('_hover.png', '')
          $(this)
            .find('img')
            .attr('src', '/front/' + img_name + '.png')
        }
      )

      //语音通知手风琴效果
      $('.voice_2 ul li').each(function () {
        var fold = $(this).find('.fold')
        var unfold = $(this).find('.unfold')
        if (fold.is(':hidden')) {
          $(this).width(445)
        } else {
          $(this).width(200)
        }
      })

      $('.voice_2 ul li').click(function () {
        var li_index = $(this).index()
        $(this).animate({ width: 445 }, 200)
        $(this).find('.unfold').show()
        $(this).find('.fold').hide()
        $(this).siblings().animate({ width: 200 }, 200)
        $(this).siblings().find('.unfold').hide()
        $(this).siblings().find('.fold').show()
      })

      //下拉框处理
      $('div.select_box ul li:even').css('background', '#f5f5f5')

      $('div.select_box').click(function (e) {
        if ('readonly' == $(this).attr('readonly')) {
          return false
        }
        e.stopPropagation()
        $(this).children('ul').toggle()
        $(this).toggleClass('focus')
      })
    })
  }
  getAdd() {
    console.log(this)
    let name = this.refs.name.state.value
    let phone = this.refs.phone.state.value
    let teamName = this.refs.teamName.state.value
    let type = document.getElementById('types').value
    // let type = this.refs.type.state.value
    console.log(name, phone, teamName, type)
    this.setState({ isloading: true })
    console.log(type)
    if (name !== undefined && name !== '') {
      if (phone !== undefined && phone !== '') {
        if (teamName !== undefined && teamName !== '') {
          // if (type !== undefined && type !== '') {
          setTimeout(async () => {
            this.setState({ isloading: false })
            let res = await requestw({
              url: 'https://lubanweb.bld365.com/api/home/fillIn',
              data: {
                userName: name,
                //  userType:this.state.modaltitle=='我是劳动者'? 'PERSON' :'COMPANY',
                phoneNumber: phone,
                userType: 'INSTALL',
                userPosition: teamName,
                remark: type,
              },
            })
            if (res.code == '0') {
              message.success('提交成功')
            } else {
              message.warning(res.data ? res.data : '提交失败')
            }
            // message.success('提交成功')
          }, 1000)
          // } else {
          //   setTimeout(() => {
          //     message.success('提交成功')
          //     // message.error('请输入备注')
          //     this.setState({ isloading: false })
          //   }, 1000)
          // }
        } else {
          setTimeout(() => {
            message.error('请输入职位名称')
            this.setState({ isloading: false })
          }, 1000)
        }
      } else {
        setTimeout(() => {
          message.error('请输入联系电话')
          this.setState({ isloading: false })
        }, 1000)
      }
    } else {
      setTimeout(() => {
        message.error('请输入姓名')
        this.setState({ isloading: false })
      }, 1000)
    }
  }
  toipt() {
    window.location.hash = '#abc'
  }
  //渲染渲染渲染渲染渲染渲染渲染渲染渲染渲染渲染渲染渲染渲染渲染渲染渲染渲染渲染渲染渲染渲染渲染渲染渲染渲染渲染渲染渲染渲染渲染
  render() {
    const { isloading } = this.state

    //domdomdomdomdomdomdomdomdomdomdomdomdomdomdomdomdomdomdomdomdomdomdomdomdomdomdomdomdomdomdomdomdomdomdom
    return (
      <div className="newretail_page">
        <PageBg
          bgSrc={require('../../static/images/home/Community/Community1.png')}
          // titleM="云商城"
          // titleM="全渠道小程序新零售解决方案"
          // descp="助力企业构建私域流量进入品牌新零售新时代"

          noBottomText
        />

        {/* 企业大数据源 */}
        {/* <div className="shujuyuan overhidden common_item_wrap" style={sty}>
          <div
            className="flexColumn newretail_titlewrap"
            style={{
              marginBottom: '50px',
            }}
          >
            <div className="newretail_titlewrap_title">S2b2C模式演进</div>

         
          </div>
          <div
            style={{
              width: '100%',
              height: '502px',
            }}
          >
            <div style={{ margin: '0 auto', width: '1213px', height: '502px', position: 'relative' }}>
              <img
                src={require('../../static/images/S2b2cwang.png')}
                style={{
                  width: '1213px',
                  height: '502px',
                }}
                alt="辽宁便利电科技"
              />
              <div style={{ position: 'absolute', left: '200px', bottom: '90px', fontSize: '18px', fontWeight: '400' }}>
                <p style={{ marginBottom: '5px' }}>消费互联网：信息对称+效率+用户体验</p>
                <p>产业互联网：效率提升+消费互联网服务</p>
              </div>
            </div>
          </div>
        </div> */}
        <img style={{ width: '100%' }} src={require('../../static/images/home/Community/Community2.png')} />
        <img style={{ width: '100%' }} src={require('../../static/images/home/Community/Community3.png')} />
        <img style={{ width: '100%' }} src={require('../../static/images/home/Community/Community4.png')} />
        <img style={{ width: '100%' }} src={require('../../static/images/home/Community/Community5.png')} />

        {/* 关系图 */}
        {/* <div className="guanxi overhidden common_item_wrap" style={sty2}>
          <div className="flexColumn newretail_titlewrap">
            <div
              className="newretail_titlewrap_title"
              style={{ color: '#fff' }}
            >
              S2b2C模式演进
            </div>
         
          </div>
          <Row
            type="flex"
            align="middle"
            justify="space-between"
            className="common_wrap "
            style={{
              width: '1189px',
              height: '626px',
              zoom: '0.8',
            }}
          >
            <img
              src={require('../../static/images/S2b2cliucheng.png')}
              style={{
                width: '100%',
                height: '90%',
                margin: '0 auto',
              }}
              alt="辽宁便利电科技"
            />
         
          </Row>
          <div
            className="common_wrap flexCenter flexBetween"
            style={{
              color: 'rgba(255,255,255,0.8)',
              padding: '0 152px',
              margin: '20px auto 35px',
            }}
          >
            <div
              style={{
                width: '507px',
                height: '44px',
                textAlign: 'center',
                margin: '0 auto',
                fontSize: '16px',
              }}
            >
              优化流程可以让效率提示30%，重构流程可以让效率至少提升100%
              重构生产要素从而达到目的，就像马车升级成汽车。
            </div>
          </div>
        </div> */}

        {/* 整合 */}
        <div className="zhenghe overhidden common_item_wrap"></div>
      </div>
    )
  }
}
