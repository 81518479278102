/**
 * model
 * 包含state和actionCreator
 */
// import {
//   registerAjax,
// } from '../../request/api'

export const defaultState = {
  curTabIndex: 0, //当前导航的index
}

//actionCreator
export const adActions = {
  //同步dispatch
  //再次登录（刷新浏览器）
  loginAgain: (stateStr) => {
    let state = JSON.parse(stateStr)
    return {
      type: 'SAVE',
      payload: state,
    }
  },
  //更改tab index
  changeTabIndex: (index) => {
    console.log(index)
    return {
      type: 'SAVE',
      payload: {
        curTabIndex: index,
      },
    }
  },
  //异步dispatch
  //...
}
