/* eslint-disable */
import React, { Component } from 'react'
import { Provider } from 'react-redux'
import store from './redux/store'
import {
  // BrowserRouter as Router,
  HashRouter as Router,
} from 'react-router-dom'
import BasicLayout from './layouts/BasicLayout'
import './static/styles/App.css'
import requestw from './utils/requestw'
import sha1 from 'js-sha1'

class App extends Component {
  constructor(props) {
    super(props)
    this.state = {}
  }
  //周期周期周期周期周期周期周期周期周期周期周期周期周期周期周期周期周期周期周期
  componentDidMount() {
    this.preloadImg()
    this.getTicket()
  }
  uuid = (startStr = 'uid') => {
    return (
      startStr +
      'yxyxxxyyyxyx'.replace(/[xy]/g, function (c) {
        var r = (Math.random() * 16) | 0,
          v = c == 'x' ? r : (r & 0x3) | 0x8
        return v.toString(16)
      })
    )
  }

  getTicket = async () => {
    let res = await requestw({
      url: 'https://jxweb.bld365.com/web/wechat/ticket',
    })
    console.log(res.data)
    let nonceStr = this.uuid()
    let timestamp = String(Date.parse(new Date()) / 1000)
    let url = window.location.href.split('#')[0]

    let signatureStr = `jsapi_ticket=${res.data}&noncestr=${nonceStr}&timestamp=${timestamp}&url=${url}`
    console.log(signatureStr)
    let signature = sha1(signatureStr)
    console.log(signature)
    let config = {
      debug: false, // 开启调试模式,调用的所有api的返回值会在客户端alert出来，若要查看传入的参数，可以在pc端打开，参数信息会通过log打出，仅在pc端时才会打印
      appId: 'wx40c7545786b47d6c', // 必填，公众号的唯一标识
      timestamp, // 必填，生成签名的时间戳
      nonceStr, // 必填，生成签名的随机串
      signature, // 必填，签名
      jsApiList: ['playVoice', 'pauseVoice', 'stopVoice', 'onMenuShareTimeline', 'onMenuShareAppMessage', 'checkJsApi', 'chooseImage', 'uploadImage', 'onMenuShareWeibo', 'updateTimelineShareData'], // 必填，需要使用的JS接口列表
    }
    console.log(config)
    window.wx.config(config)

    window.wx.ready((e) => {
      console.log(e, 'ready')

      window.wx.updateTimelineShareData({
        title: '便利电科技', // 分享标题
        desc: '赋能数字时代，助力企业实现价值共赢。',
        link: url, // 分享链接，该链接域名或路径必须与当前页面对应的公众号JS安全域名一致
        imgUrl: 'https://cdn.s.bld365.com/bldLogo.png', // 分享图标
        type: '', // 分享类型,music、video或link，不填默认为link
        dataUrl: '', // 如果type是music或video，则要提供数据链接，默认为空
        success: function () {
          // alert('分享调用了啊，')
          console.log('分享成功')
        },
        cancel: function () {
          console.log('分享取消')
        },
      })

      window.wx.onMenuShareAppMessage({
        title: '便利电科技', // 分享标题
        desc: '赋能数字时代，助力企业实现价值共赢。',
        link: url, // 分享链接
        imgUrl: 'https://cdn.s.bld365.com/bldLogo.png', // 分享图标

        success: function () {
          // alert("成功");
        },
      })
    })
    window.wx.error((e) => {
      console.log(e, 'error')
    })
  }

  preloadImg = () => {
    let srcArr = [
      require('./static/images/carousel_bg.png'), //首页
      require('./static/images/case_bg.png'), //产品案例
      require('./static/images/newretail_bg.png'), //通信新零售
      require('./static/images/lexin/lexin_bg.png'), //灵活用工
      require('./static/images/SaaSbg2.png'), //SaaS
      require('./static/images/contactUs_bg.png'), //关于我们
      require('./static/images/joinUs_bg.png'), //加入我们
      require('./static/images/qualification_bg.png'), //企业资质
    ]
    srcArr.forEach((img) => {
      let image = new Image()
      image.src = img
    })
  }
  //渲染渲染渲染渲染渲染渲染渲染渲染渲染渲染渲染渲染渲染渲染渲染渲染渲染渲染渲染
  render() {
    return (
      <Router>
        <Provider store={store}>
          <BasicLayout />
        </Provider>
      </Router>
    )
  }
}

export default (props) => <App />
