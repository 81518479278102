/* eslint-disable */
import React, { Component, Fragment } from 'react'
//redux
import { connect } from 'react-redux'
//变量
import { carouselListText } from '../../utils/utils'
// 样式
import './PageBg.less'

class PageBg extends Component {
  componentDidMount() {
    // if ((navigator.userAgent.match(/(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i))) {
    //   window.location.href = 'http://mjianliu.bld365.com/'
    // }
  }
  toYPG(url) {
    console.log(url)
    window.open('https://yipaigong.bld365.com/#/user/login')
  }
  render() {
    const {
      bgSrc,
      titleM,
      descp,
      isFan, //title 和descp调换位置
      contentStyle, //特殊的控制content文字
      bottomText, //走马灯下面的文字可以是传进来的
    } = this.props

    //走马灯下面文字
    let showTextList = bottomText ? bottomText : carouselListText
    let carouselDivText = (
      <div className="bottomTextWrap">
        <div className="common_wrap" style={{ display: 'flex', height: '100%' }}>
          {showTextList.map((str, index) => (
            <div
              key={index}
              className="flexCenter flexJCenter"
              style={{
                height: '100%',
                flex: '1 0 0',
                fontSize: '17px',
                color: '#EAEAEA',
                borderRight: '1px solid rgba(255,255,255,0.3)',
                borderLeft: index.toString() === '0' ? '1px solid rgba(255,255,255,0.3)' : 'none',
              }}
            >
              {str}
            </div>
          ))}
        </div>
      </div>
    )
    //domdomdomdomdomdomdomdomdomdomdomdomdomdomdomdomdomdomdomdomdomdomdomdomdomdomdom
    return (
      // <div
      //   className="page_bg"
      //   style={{ minWidth: '1080px', height: '9.5%' }}
      // >
      <div>
        {' '}
        <div className="img_wrap">
          <img
            style={{ width: '100%', height: '79.5%' }}
            src={bgSrc}
            // onClick={() => {
            //   this.toYPG(bgSrc)
            // }}
            alt="辽宁便利电科技"
          />
        </div>
        <div className="content positionCenter" style={contentStyle ? contentStyle : null}>
          {isFan ? (
            <Fragment>
              <div
                className="descp"
                style={{
                  color: 'rgba(255,255,255,0.8)',
                  whiteSpace: 'nowrap',
                  textAlign: 'center',
                  marginTop: '10px',
                }}
              >
                {descp}
              </div>
              <div className="title" style={{ color: '#fff', textAlign: 'center' }}>
                {titleM}
              </div>
            </Fragment>
          ) : (
            <Fragment>
              <div className="title" style={{ color: '#fff', textAlign: 'center' }}>
                {titleM}
              </div>
              <div
                className="descp"
                style={{
                  color: 'rgba(255,255,255,0.8)',
                  whiteSpace: 'nowrap',
                  textAlign: 'center',
                  marginTop: '10px',
                }}
              >
                {descp}
              </div>
            </Fragment>
          )}
        </div>
      </div>
      // </div>
    )
  }
}

const mapStateToProps = (state) => ({
  //选择注入哪些state，state 所有的state(reducer)
  ad: state.ad,
})

export default connect(mapStateToProps)(PageBg)
