/* eslint-disable */
import React, { Component } from 'react'
// 样式
import './AppItem.less'

// const { titleM, imgSrc, qrCodeSrc,canShow } = this.props
export default class AppItem extends Component {
  constructor(props) {
    super(props)
    this.state = {
      qrW: 50,
      isShowQr: false,
    }
  }
  componentDidMount() {
    //设置二维码的宽 （appItem的高度）
    let h = document.getElementsByClassName('app_item')[0].clientHeight + 6
    this.setState({
      qrW: h,
    })
  }
  show = () => {
    const { canShow } = this.props
    if (canShow) {
      this.setState({
        isShowQr: true,
      })
    }
  }
  hide = () => {
    this.setState({
      isShowQr: false,
    })
  }
  render() {
    const { titleM, imgSrc, qrCodeSrc } = this.props
    return (
      <div className="app_item" onMouseEnter={this.show} onMouseLeave={this.hide}>
        <div className="imgBox">
          <img className="img100" src={imgSrc} alt="辽宁便利电科技" />
        </div>
        <div style={{ fontSize: '14px', textAlign: 'center', marginTop: '10px' }}>{titleM}</div>

        {this.state.isShowQr ? (
          <div className="qrCode" onMouseLeave={this.hide} style={{ width: this.state.qrW, height: this.state.qrW }}>
            <img src={qrCodeSrc} alt="辽宁便利电科技" />
          </div>
        ) : null}
      </div>
    )
  }
}
