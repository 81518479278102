/* eslint-disable */
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { adActions } from '../../redux/models/ad'
import { Link } from 'react-router-dom'
import { mobileW } from '../../utils/utils'
import QueueAnim from 'rc-queue-anim'
import ScrollAnim from 'rc-scroll-anim'
import './Footer.less'
import icon_gongan from '../../static/images/icon_gongan.jpg'

const ScrollOverPack = ScrollAnim.OverPack

const QRcodeSrc = require('../../static/images/qr_appcenter.jpg')

class Footer extends Component {
  clickTab = (e) => {
    let ele = e.target
    let idx = Number(ele.getAttribute('data-index'))
    const { dispatch } = this.props
    dispatch(adActions.changeTabIndex(idx))
  }
  goProductIndex = () => {
    const { dispatch } = this.props
    dispatch(adActions.changeTabIndex(1))
  }
  goLexin = () => {
    // const { dispatch } = this.props
    // dispatch(adActions.changeTabIndex(3))
    window.location.href = 'https://lexin.bld365.com/#/user/login'
  }
  render() {
    return (
      <div className="footer">
        <div className="flexCenter flexAround container_wrap">
          <div className="container">
            <div className="title">便利电科技</div>
            <div className="container_content">
              <Link
                to="/aboutus"
                data-index={4}
                onClick={(e) => {
                  this.clickTab(e)
                }}
              >
                关于我们
              </Link>
              {/* <Link
                to="/joinus"
                data-index={5}
                onClick={(e) => {
                  this.clickTab(e)
                }}
              >
                加入我们
              </Link> */}
              <Link
                to="/qualification"
                data-index={6}
                onClick={(e) => {
                  this.clickTab(e)
                }}
              >
                企业资质
              </Link>
            </div>
          </div>
          <div className="container">
            <div className="title">产品中心</div>
            <div className="container_content">
              {/* <Link to='/product' onClick={this.goProductIndex}>京东通信（商用版）</Link>
              <Link to='/product' onClick={this.goProductIndex}>京东通信（物流版）</Link>
              <a onClick={this.goLexin}>乐薪平台</a>
              <Link to='/product' onClick={this.goProductIndex}>和伙人</Link> */}
              <div>云名片</div>
              <div>云学院</div>
              <div>云商城</div>
              <div
              // onClick={this.goLexin}
              >
                云结算
              </div>
              <div>云派工</div>
              <div>云直播</div>
            </div>
          </div>
          <div className="container">
            <div className="title">联系我们</div>
            <div className="container_content">
              <div>400-086-0606</div>
              <div>周一至周日: 08:00~18:00</div>
            </div>
          </div>
          <div className="container">
            {window.screen.width > mobileW ? (
              <div style={{ width: '105px', height: '105px' }}>
                <ScrollOverPack playScale={[0.15, 0.15]}>
                  <QueueAnim type={['right', 'left']} leaveReverse={true} forcedReplay={true} delay={10}>
                    <img key="1" className="qrCode" src={QRcodeSrc} alt="辽宁便利电科技" />
                  </QueueAnim>
                </ScrollOverPack>
              </div>
            ) : (
              <div style={{ width: '105px', height: '105px' }}>
                <QueueAnim type={['right', 'left']} leaveReverse={true} forcedReplay={true} delay={10}>
                  <img key="1" className="qrCode" src={QRcodeSrc} alt="辽宁便利电科技" />
                </QueueAnim>
              </div>
            )}
          </div>
        </div>

        <div className="footer_bot flexCenter flexJCenter">
          <a target="_blank" href="https://www.beian.gov.cn/portal/registerSystemInfo?recordcode=21011202000408" style={{ display: 'flex', marginRight: 12 }}>
            <img src={icon_gongan} style={{ width: 16, height: 'auto', position: 'relative', top: -1 }} />
            <span style={{ color: '#fff' }}>辽公网安备 21011202000408号</span>
          </a>
          <a target="_blank" href="https://beian.miit.gov.cn" style={{ color: '#fff' }}>
            便利电 辽B2-20170094-4
          </a>
        </div>
      </div>
    )
  }
}

const mapStateToProps = (state) => ({
  //选择注入哪些state，state 所有的state(reducer)
  ad: state.ad,
})

export default connect(mapStateToProps)(Footer)
