/* eslint-disable */
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { adActions } from '../../redux/models/ad'
import PageBg from '../../components/PageBg/PageBg'
import { Link, withRouter } from 'react-router-dom'
import SuccessCase from '../../components/SuccessCase/SuccessCase'
import './Home.less'
import { fuwuList1, fuwuList2, FuwuItem } from '../jianliu/jianliu'

let gundongTimer = null
const transitionTime = 0.2 //单位s

class Home extends Component {
  constructor(props) {
    super(props)
    this.state = {
      // 走马灯
      tabActiveIndex: 0,
      carouselList: [
        {
          title: '自定义搭建企业数字化管理平台',
          descp: '自定义搭建企业数字化管理平台',
          imgBg: require('../../static/images/carousel_bg.png'),
        },
      ],
      //大图和文字item
      bigImgTextList: [
        {
          title: '物流行业赋能',
          descp: '简单易用、自动化办理、数据统计清晰',
          imgSrc: require('../../static/images/home/bigImgText/home01.png'),
        },
        {
          title: '新零售通信+平台',
          descp: '简单易用、自动化办理、数据统计清晰',
          imgSrc: require('../../static/images/home/bigImgText/home02.png'),
        },
        {
          title: '乐薪灵活用工平台',
          descp: '简单易用、自动化办理、数据统计清晰',
          imgSrc: require('../../static/images/home/bigImgText/home03.png'),
        },
      ],
      //合作伙伴
      partnerList: [
        //真实
        {
          imgSrc: require('../../static/images/home/partner/partner_JD.png'), //京东
          imgW: '114px',
          imgH: '23px',
        },
        {
          imgSrc: require('../../static/images/home/partner/partner_dianxin.png'), //电信
          imgW: '93px',
          imgH: '28px',
        },
        {
          imgSrc: require('../../static/images/home/partner/partner_gree.png'), //移动不让用了 改成gree
          imgW: '112px',
          imgH: '27px',
        },
        {
          imgSrc: require('../../static/images/home/partner/partner_liantong.png'), //联通
          imgW: '67px',
          imgH: '37px',
        },
        {
          imgSrc: require('../../static/images/home/partner/partner_wuliu.png'), //京东物流
          imgW: '115px',
          imgH: '27px',
        },
        {
          imgSrc: require('../../static/images/home/partner/partner_pinganyinhang.png'), //平安银行
          imgW: '93',
          imgH: '32px',
        },
        {
          imgSrc: require('../../static/images/home/partner/partner_zhaoshangyinhang.png'), //招商银行
          imgW: '101px',
          imgH: '24px',
        },
        {
          imgSrc: require('../../static/images/home/partner/partner_zhongguoyouzheng.png'), //中国邮政
          imgW: '101px',
          imgH: '24px',
        },
        {
          imgSrc: require('../../static/images/home/partner/partner_zhongguoguangdayinhang.png'), //中国光大银行
          imgW: '156px',
          imgH: '41px',
        },
        {
          imgSrc: require('../../static/images/home/partner/partner_shengjingyinhang.png'), //盛京银行
          imgW: '103px',
          imgH: '27px',
        },
        {
          imgSrc: require('../../static/images/home/partner/partner_xiaomi.png'), //小米
          imgW: '81px',
          imgH: '34px',
        },
        {
          imgSrc: require('../../static/images/home/partner/partner_meituan.png'), //美团
          imgW: '101px',
          imgH: '37px',
        },
        {
          imgSrc: require('../../static/images/home/partner/partner_aiqiyi.png'), //爱奇艺
          imgW: '103px',
          imgH: '35px',
        },
        {
          imgSrc: require('../../static/images/home/partner/partner_shunfengsuyun.png'), //顺丰速运
          imgW: '103px',
          imgH: '38px',
        },
        {
          imgSrc: require('../../static/images/home/partner/partner_etc.png'), //ETC
          imgW: '103px',
          imgH: '24px',
        },
        //真实 end
      ],

      shuziList: [
        {
          name: '价值一',
          title: '高稳定性满足企业稳态业务开发维护需求',
          value: '低代码产品稳定性高，降低稳态业务后期运维成本。',
        },
        {
          name: '价值二',
          title: '灵活性和通用性适应企业敏态业务变化',
          value: '通过可复用模型 实现软件快速开 发和部署，缩短 项目交付周期。',
        },
        {
          name: '价值三',
          title: '各部门数据的集成打通企业内部数据孤岛',
          value: '集成企业内部各系统，连接核心业务所需数据，提高业务部门工作效率。',
        },
      ],
      sigeqiyeList: [
        {
          name: '企业个性化特定场景应用',
          title: '企业个性化特定场景应用',
          value:
            '企业个性化应用，区别于综合型管理 系统，应用于各类型企业的特定场景。 比如图书管理、运维平台、视频会议、 车辆的管理、创新项目管理等，这类 场景通常可以由业务人员利用低代码 平台进行快速开发。',
        },
        {
          name: '通用性业务场景',
          title: '通用性业务场景',
          value: '国内管理型软件厂商如用友、金蝶等， 提供财务软件、人事管理、ERP、OA、 CRM、日程管理、会议管理等通用性软件，通用性场景通常行业属性不强，可以跨领域、跨企业应用，应用范围广泛。',
        },
        {
          name: '中小企业细分应用场景',
          title: '中小企业细分应用场景',
          value: '中小企业体量小，组织架构扁平化、 应用场景较为简单，通常会选择低代 码这种轻量级开发方式用来做细分行 业的核心业务系统，比如生产管理中 中小企业模具生产、玻璃深加工、智 能锁制造等。',
        },
        {
          name: '企业综合型管理系统',
          title: '企业综合型管理系统',
          value: '综合型管理系统通常用于中大型企业整个内部管理，属于企业级应用，场景相对复杂。通常会根据企业需求并利用低代码平台提供定制化解决方案。',
        },
      ],
      itemw: 0,
      partnerIndex: 0,
      isH5: false,
    }
  }
  //周期周期周期周期周期周期周期周期周期周期周期周期周期周期周期周期周期周期周期周期周期周期周期周期周期周期周期周期
  componentWillMount() {
    localStorage.setItem('bldweb', '')
    if (navigator.userAgent.match(/(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i)) {
      this.setState({
        isH5: true,
      })
    }
  }
  componentDidMount() {
    window.scrollTo(0, 0)
    const { dispatch } = this.props
    dispatch(adActions.changeTabIndex(0))
  }

  //初始化合作伙伴滚动
  initPartnerGundong = () => {
    let { partnerList } = this.state
    let scrollWrap = document.getElementsByClassName('scrollWrap')[0]

    let wrapw = scrollWrap.clientWidth
    let itemw = wrapw / partnerList.length
    this.setState({ itemw })

    this.beginGundong()
  }
  //开始滚动
  beginGundong = () => {
    let scrollWrap = document.getElementsByClassName('scrollWrap')[0]
    gundongTimer = setInterval(() => {
      let { partnerIndex } = this.state
      scrollWrap.style.transition = `all ${transitionTime}s ease-in-out`
      this.setState(
        {
          partnerIndex: partnerIndex - 1,
        },
        () => {
          setTimeout(() => {
            let { partnerIndex } = this.state
            if (partnerIndex <= -8) {
              scrollWrap.style.transition = 'none'
              this.setState({ partnerIndex: 0 })
            }
          }, transitionTime * 1000) //与transition的时间相同
        }
      )
    }, 2000)
  }
  //停止滚动
  stopGundong = () => {
    window.clearInterval(gundongTimer)
    gundongTimer = null
  }

  handleTabClick(tabActiveIndex) {
    console.log(tabActiveIndex)
    this.setState({
      tabActiveIndex,
    })
  }

  tojianliuitme = () => {
    const { dispatch } = this.props
    dispatch(adActions.changeTabIndex(2))
  }

  tohangye = () => {
    const { dispatch } = this.props
    dispatch(adActions.changeTabIndex(2))
  }

  //渲染渲染渲染渲染渲染渲染渲染渲染渲染渲染渲染渲染渲染渲染渲染渲染渲染渲染渲染渲染渲染渲染渲染渲染渲染渲染渲染渲染渲染
  render() {
    const {
      partnerList, //合作伙伴
      shuziList,
      tabActiveIndex,
      isH5,
    } = this.state

    // 服务
    let fuwuDiv1 = fuwuList1.slice(0, 3).map((obj, ind) => <FuwuItem key={ind} item={obj} emitClick={this.tojianliuitme} />)
    let fuwuDiv2 = fuwuList2.slice(0, 3).map((obj, ind) => <FuwuItem key={ind} item={obj} emitClick={this.tojianliuitme} />)

    //合作伙伴
    let partnerDiv = partnerList.map((obj, index) => (
      <div key={index} className="parter_item_wrap">
        <div className="partner_item" key={index}>
          <img className="positionCenter" src={obj.imgSrc} alt="辽宁便利电科技" style={{ width: obj.imgW, height: obj.imgH }} />
        </div>
      </div>
    ))
    //domdomdomdomdomdomdomdomdomdomdomdomdomdomdomdomdomdomdomdomdomdomdomdomdomdomdomdomdomdomdomdomdomdomdomdomdomdomdomdomdomdomdomdom
    return (
      <div className="home_page">
        <PageBg
          bgSrc={require('../../static/images/carousel_bg.png')}
          titleM={
            <>
              <p
                style={{
                  fontSize: '52px',
                  textAlign: 'center',
                  marginTop: '50px',
                }}
              >
                自定义搭建企业数字化管理平台
              </p>
              <p
                style={{
                  fontSize: '22px',
                  textAlign: 'center',
                  color: 'rgba(255,255,255,1)',
                  marginTop: '15px',
                  whiteSpace: 'nowrap',
                }}
              >
                以场景为中心，让使用者可以可视化和模块化搭建应用系统，通过产品组合为企业数字化转型赋能
              </p>
            </>
          }
        />

        {/* 2021年5月10日17:03:46新增中国中小企业数字化趋势 */}
        <div className="datu overhidden common_item_wrap" style={{ backgroundColor: '#fff' }}>
          <div className="flexColumn home_titlewrap" style={{ marginTop: '40px', marginBottom: '50px' }}>
            <div className="home_titlewrap_title">中国中小企业数字化趋势</div>
            <div className="home_titlewrap_descp">预计2025年达到131亿，未来5年复合增速为52.6%</div>
          </div>
          <div style={{ width: 949, margin: '0 auto', height: 'auto' }}>
            <img src={require('../../static/images/home/homezhexian.png')} style={{ width: 949, margin: '0 auto', height: 'auto' }} />
          </div>
        </div>
        {/* 中国中小企业数字化趋势 end */}

        {/* 2021年5月10日17:03:46新增 为什么选择数字化 */}
        <div className="datu overhidden common_item_wrap" style={{ paddingBottom: 0 }}>
          <div className={isH5 ? '' : 'shuzibgimg'} style={{ paddingBottom: 30, paddingTop: 20 }}>
            <div className="flexColumn home_titlewrap" style={{ marginTop: '20px', marginBottom: '10px' }}>
              <div className="home_titlewrap_title">为什么选择数字化</div>
              <div className="home_titlewrap_descp">从“你生产什么，我要什么”到“我要什么，你生产什么”的转变</div>
            </div>
            <div style={{ width: 949, margin: '0 auto', height: '100%' }}>
              {shuziList.map((item, ind) => {
                return (
                  <div className="shuzihuaitme">
                    <div className="shuzihualeftbox">
                      <div className="bluebox" />
                      <div className="lefttxt">{item.name}</div>
                    </div>
                    <div className="shuzihuarightbox">
                      <div className="righttitle">{item.title}</div>
                      <div className="rightvalue">{item.value}</div>
                    </div>
                  </div>
                )
              })}
            </div>
          </div>
        </div>
        {/* 为什么选择数字化 end */}
        {/* 2021年5月10日17:03:46新增 企业数字化应用场景 */}
        <div className="datu overhidden common_item_wrap" style={{ backgroundColor: '#fff' }}>
          <div className="flexColumn home_titlewrap" style={{ marginTop: '40px', marginBottom: '50px' }}>
            <div className="home_titlewrap_title">企业数字化应用场景</div>
            <div className="home_titlewrap_descp">可覆盖制造业、金融、医疗、房地产、零售、餐饮、航空等众多行业的不同应用场景</div>
          </div>
          <div style={{ width: 949, margin: '0 auto', height: 'auto' }}>
            <div className="sigeqiyebox">
              <div className="sigeqiyeitem" style={{ marginRight: 30, marginTop: 24 }}>
                <div className="sigeqiyettitle">
                  <div className="sigeqiyelogo">
                    <img src={require('../../static/images/home/sigeqiye/sigeqiye1.png')} />
                  </div>
                  <div className="sigeqiyetitletxt">企业个性化特定场景应用</div>
                </div>
                <div className="sigeqiyetvalue">
                  企业个性化应用，区别于综合型管理 系统，应用于各类型企业的特定场景。 比如图书管理、运维平台、视频会议、 车辆的管理、创新项目管理等，这类 场景通常可以由业务人员利用低代码
                  平台进行快速开发。
                </div>
              </div>

              <div className="sigeqiyeitem" style={{ marginTop: 24 }}>
                <div className="sigeqiyettitle">
                  <div className="sigeqiyelogo">
                    <img src={require('../../static/images/home/sigeqiye/sigeqiye2.png')} />
                  </div>
                  <div className="sigeqiyetitletxt">通用性业务场景</div>
                </div>
                <div className="sigeqiyetvalue">
                  国内管理型软件厂商如用友、金蝶等， 提供财务软件、人事管理、ERP、OA、 CRM、日程管理、会议管理等通用性软件，通用性场景通常行业属性不强，可以跨领域、跨企业应用，应用范围广泛。
                </div>
              </div>

              <div className="sigeqiyeitem" style={{ marginRight: 30, marginTop: 24 }}>
                <div className="sigeqiyettitle">
                  <div className="sigeqiyelogo">
                    <img src={require('../../static/images/home/sigeqiye/sigeqiye3.png')} />
                  </div>
                  <div className="sigeqiyetitletxt">中小企业细分应用场景</div>
                </div>
                <div className="sigeqiyetvalue">
                  中小企业体量小，组织架构扁平化、 应用场景较为简单，通常会选择低代 码这种轻量级开发方式用来做细分行 业的核心业务系统，比如生产管理中 中小企业模具生产、玻璃深加工、智 能锁制造等。
                </div>
              </div>

              <div className="sigeqiyeitem" style={{ marginTop: 24 }}>
                <div className="sigeqiyettitle">
                  <div className="sigeqiyelogo">
                    <img src={require('../../static/images/home/sigeqiye/sigeqiye4.png')} />
                  </div>
                  <div className="sigeqiyetitletxt">企业综合型管理系统</div>
                </div>
                <div className="sigeqiyetvalue">综合型管理系统通常用于中大型企业整个内部管理，属于企业级应用，场景相对复杂。通常会根据企业需求并利用低代码平台提供定制化解决方案。</div>
              </div>
            </div>
          </div>
        </div>
        {/* 企业数字化应用场景 end */}

        {/* 2021年5月10日17:03:46新增 数字化为企业创造无尽价值 */}
        <div className="datu overhidden common_item_wrap">
          <div className="flexColumn home_titlewrap" style={{ marginTop: '40px', marginBottom: '50px' }}>
            <div className="home_titlewrap_title">数字化为企业创造无尽价值</div>
            <div className="home_titlewrap_descp">我们为各行各业的业务管理人员提供了开箱即用的解决方案，不仅如此，借助无代码搭建平台的能力，您还可以在此基础上进行快速定制。</div>
          </div>
          <div className="m-sys-wrap">
            <div className="m-sys-inner">
              <div className="m-sys-header">
                <ul className="m-sys-tab-wrap">
                  <li className={'m-sys-tab ' + (tabActiveIndex === 0 ? 'active' : '')} style={{ marginRight: 10 }} onClick={this.handleTabClick.bind(this, 0)}>
                    <span className="m-sys-tab-text">通用解决方案</span>
                  </li>
                  <li className={'m-sys-tab ' + (tabActiveIndex === 1 ? 'active' : '')} onClick={this.handleTabClick.bind(this, 1)}>
                    <span className="m-sys-tab-text">行业解决方案</span>
                  </li>
                </ul>
              </div>
              <div className="m-sys-content" style={{ paddingTop: 5 }}>
                <div className={'m-sys-view ' + (tabActiveIndex === 0 ? 'active' : '')}>
                  <>{fuwuDiv1}</>
                </div>
                <div className={'m-sys-view ' + (tabActiveIndex === 1 ? 'active' : '')}>
                  <>{fuwuDiv2}</>
                </div>
              </div>
            </div>
          </div>
          <div style={{ width: 949, margin: '0 auto', height: 'auto' }} className="parent">
            <Link to="/jianliu" className="son" onClick={this.tohangye} style={{ color: '#3F7DEB', fontSize: 11 }}>
              查看更多
            </Link>
          </div>
        </div>

        {/* 成功案例 */}
        <SuccessCase />

        {/* 合作伙伴 */}
        <div className="partner overhidden">
          <div className="flexColumn home_titlewrap">
            <div className="home_titlewrap_title">合作伙伴</div>
            <div className="home_titlewrap_descp">自定义搭建企业数字化管理平台</div>
          </div>

          <div className="common_wrap flexCenter flexBetween partner_wrap" style={{ flexWrap: 'wrap' }}>
            {partnerDiv}
          </div>
        </div>
      </div>
    )
  }
}

const mapStateToProps = (state) => ({
  //选择注入哪些state，state 所有的state(reducer)
  ad: state.ad,
})
export default connect(mapStateToProps)(withRouter(Home))
