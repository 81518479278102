/* eslint-disable */
import React, { Component, Fragment } from 'react'
//redux
import { connect } from 'react-redux'
//变量
import { carouselListText } from '../../utils/utils'
// 样式
import './PageBg.less'

class PageBg extends Component {
  render() {
    const {
      bgSrc,
      titleM,
      descp,
      isFan, //title 和descp调换位置
      contentStyle, //特殊的控制content文字
      bottomText, //走马灯下面的文字可以是传进来的
      noBottomText,
    } = this.props

    //走马灯下面文字
    // let showTextList = bottomText ? bottomText : carouselListText
    // console.log(showTextList);
    let carouselDivText = (
      <div className="bottomTextWrap">
        <div className="common_wrap" style={{ display: 'flex', height: '100%' }}>
          {carouselListText.map((str, index) => (
            <div
              key={index}
              className="flexCenter flexJCenter"
              style={{
                height: '100%',
                flex: '1 0 0',
                fontSize: '17px',
                color: '#EAEAEA',
                borderRight: '1px solid rgba(255,255,255,0.3)',
                borderLeft: index.toString() === '0' ? '1px solid rgba(255,255,255,0.3)' : 'none',
              }}
            >
              {str}
            </div>
          ))}
        </div>
      </div>
    )
    //domdomdomdomdomdomdomdomdomdomdomdomdomdomdomdomdomdomdomdomdomdomdomdomdomdomdom
    return (
      <div className="page_bg" style={{ minWidth: '1080px', minHeight: '412px' }}>
        <div className="img_wrap">
          <img className="img100" src={bgSrc} alt="辽宁便利电科技" />
        </div>
        <div className="content positionCenter" style={contentStyle ? contentStyle : null}>
          {isFan ? (
            <Fragment>
              <div
                className="descp"
                style={{
                  color: 'rgba(255,255,255,0.8)',
                  whiteSpace: 'nowrap',
                  textAlign: 'center',
                  marginTop: '10px',
                }}
              >
                {descp}
              </div>
              <div className="title" style={{ color: '#fff', textAlign: 'center' }}>
                {titleM}
              </div>
            </Fragment>
          ) : (
            <Fragment>
              <div className="title" style={{ color: '#fff', textAlign: 'center' }}>
                {titleM}
              </div>
              <div
                className="descp"
                style={{
                  color: 'rgba(255,255,255,0.8)',
                  whiteSpace: 'nowrap',
                  textAlign: 'center',
                  marginTop: '10px',
                }}
              >
                {descp}
              </div>
            </Fragment>
          )}
        </div>
        {
          // noBottomText?null:<>{carouselDivText}</>
          carouselDivText
        }
      </div>
    )
  }
}

const mapStateToProps = (state) => ({
  //选择注入哪些state，state 所有的state(reducer)
  ad: state.ad,
})

export default connect(mapStateToProps)(PageBg)
