/* eslint-disable */
import React, { Component } from 'react'
import $ from 'jquery'
import { Row, Col, Input, Button, message } from 'antd'
import PageBg from '../../components/PageBg/PageBg'
import QueueAnim from 'rc-queue-anim'
import ScrollAnim from 'rc-scroll-anim'
import './SaaS.less'
import './base.css'
import Background from '../../static/images/S2b2cbg.png'
import Background2 from '../../static/images/S2b2cbg2.png'
import Background3 from '../../static/images/S2b2C/dengbg.png'
import Background4 from '../../static/images/S2b2C/xiaochengxbg2.png'
import Background5 from '../../static/images/S2b2C/ledabg.png'

const ScrollOverPack = ScrollAnim.OverPack

//菜单数据

export default class SaaS extends Component {
  constructor(props) {
    super(props)
    this.state = {
      isloading: false,
      teamList: [
        {
          url: require('../../static/images/S2b2C/teamlist/teamitem2.png'),
          imgsty: {
            width: '110px',
            height: '33px',
          },
        },
        {
          url: require('../../static/images/S2b2C/teamlist/teamitem3.png'),
          imgsty: {
            width: '77px',
            height: '42px',
          },
        },
        {
          url: require('../../static/images/S2b2C/teamlist/teamitem4.png'),
          imgsty: {
            width: '127px',
            height: '40px',
          },
        },
        {
          url: require('../../static/images/S2b2C/teamlist/teamitem5.png'),
          imgsty: {
            width: '137px',
            height: '32px',
          },
        },
        {
          url: require('../../static/images/S2b2C/teamlist/teamitem6.png'),
          imgsty: {
            width: '141px',
            height: '34px',
          },
        },
        {
          url: require('../../static/images/S2b2C/teamlist/teamitem7.png'),
          imgsty: {
            width: '117px',
            height: '34px',
          },
        },
        {
          url: require('../../static/images/S2b2C/teamlist/teamitem8.png'),
          imgsty: {
            width: '141px',
            height: '37px',
          },
        },
        {
          url: require('../../static/images/S2b2C/teamlist/teamitem_shengjing_bank.png'),
          imgsty: {
            width: '130px',
            height: '34px',
          },
        },
        {
          url: require('../../static/images/S2b2C/teamlist/teamitem10.png'),
          imgsty: {
            width: '97px',
            height: '34px',
          },
        },
        {
          url: require('../../static/images/S2b2C/teamlist/teamitem11.png'),
          imgsty: {
            width: '144px',
            height: '25px',
          },
        },
        {
          url: require('../../static/images/S2b2C/teamlist/teamitem12.png'),
          imgsty: {
            width: '68x',
            height: '43px',
          },
        },
        {
          url: require('../../static/images/S2b2C/teamlist/teamitem13.png'),
          imgsty: {
            width: '128px',
            height: '30px',
          },
        },
        {
          url: require('../../static/images/S2b2C/teamlist/teamitem14.png'),
          imgsty: {
            width: '111px',
            height: '36px',
          },
        },
        {
          url: require('../../static/images/S2b2C/teamlist/teamitem15.png'),
          imgsty: {
            width: '54px',
            height: '43px',
          },
        },
        {
          url: require('../../static/images/S2b2C/teamlist/teamitem_mangguo.png'),
          imgsty: {
            width: '110px',
            height: '30px',
          },
        },
      ],
      blueList: [
        {
          url: require('../../static/images/S2b2C/blueitem1.png'),
          styl: { marginRight: '59px', width: '162px', height: '162px' },
        },
        {
          url: require('../../static/images/S2b2C/blueitem2.png'),
          styl: { marginRight: '59px', width: '162px', height: '162px' },
        },
        {
          url: require('../../static/images/S2b2C/blueitem3.png'),
          styl: { marginRight: '59px', width: '162px', height: '162px' },
        },
        {
          url: require('../../static/images/S2b2C/blueitem4.png'),
          styl: { marginRight: '59px', width: '162px', height: '162px' },
        },
        {
          url: require('../../static/images/S2b2C/blueitem5.png'),
          styl: { width: '162px', height: '162px' },
        },
      ],
      greeList: [
        {
          url: require('../../static/images/S2b2C/greeitem1.png'),
          styl: { marginRight: '59px', width: '162px', height: '162px' },
        },
        {
          url: require('../../static/images/S2b2C/greeitem2.png'),
          styl: { marginRight: '59px', width: '162px', height: '162px' },
        },
        {
          url: require('../../static/images/S2b2C/greeitem3.png'),
          styl: { marginRight: '59px', width: '162px', height: '162px' },
        },
        {
          url: require('../../static/images/S2b2C/greeitem4.png'),
          styl: { marginRight: '59px', width: '162px', height: '162px' },
        },
        {
          url: require('../../static/images/S2b2C/greeitem5.png'),
          styl: { width: '162px', height: '162px' },
        },
      ],
      //企业大数据源
      shujuyuanList: [
        {
          title: '行为数据源',
          tips: '因用户个人个体行为而产生的数据',
          tips2: '门店客流、APP行为、客户评论等',
          img: require('../../static/images/newretail/shujuyuan/xwsjy.png'),
          size: { w: 125, h: 122 },
        },
        {
          title: '商业经营数据来源',
          tips: '围绕交易和内部管理系统运行产生的数据',
          tips2: '交易订单、供应链、智能管理等',
          img: require('../../static/images/newretail/shujuyuan/syjysjly.png'),
          size: { w: 128, h: 128 },
        },
        {
          title: '技术运维数据源',
          tips: '在技术产品运行过程中产生的数据',
          tips2: '云监控、运行日志、系统异常等',
          img: require('../../static/images/newretail/shujuyuan/jsywsjy.png'),
          size: { w: 131, h: 116 },
        },
        {
          title: '第三方数据源',
          tips: '第三方公司所公开提供的一些数据',
          tips2: '腾讯微信、国家统计局、网络应用等',
          img: require('../../static/images/newretail/shujuyuan/dsfsjy.png'),
          size: { w: 125, h: 92 },
        },
      ],
      //整合
      zhengheList: [
        {
          title: '客户触点',
          img: require('../../static/images/newretail/zhenghe/khcd.png'),
          left: -67, //控制位置
          aniType: ['left', 'right'],
          delay: 100,
        },
        {
          title: '门店触点',
          img: require('../../static/images/newretail/zhenghe/mdcd.png'),
          left: -39, //控制位置
          aniType: ['left', 'right'],
          delay: 0,
        },
        {
          title: '总部触点',
          img: require('../../static/images/newretail/zhenghe/zbcd.png'),
          left: 64, //控制位置
          aniType: ['right', 'left'],
          delay: 100,
        },
        {
          title: '数字触点',
          img: require('../../static/images/newretail/zhenghe/szcd.png'),
          left: 65, //控制位置
          aniType: ['right', 'left'],
          delay: 200,
        },
      ],
    }
  }
  //周期周期周期周期周期周期周期周期周期周期周期周期周期周期周期周期周期周期周期周期周期周期周期周期周期周期周期周期
  componentDidMount() {
    window.scrollTo(0, 0)
    this.runJq()
  }
  runJq() {
    $(function () {
      //导航菜单
      var ytx = {}
      $('[t_nav]').hover(
        function () {
          var _nav = $(this).attr('t_nav')
          clearTimeout(ytx[_nav + '_timer'])
          ytx[_nav + '_timer'] = setTimeout(function () {
            $('#' + _nav)
              .stop(true, true)
              .slideDown(200)
          }, 150)
        },
        function () {
          var _nav = $(this).attr('t_nav')
          clearTimeout(ytx[_nav + '_timer'])
          ytx[_nav + '_timer'] = setTimeout(function () {
            $('#' + _nav)
              .stop(true, true)
              .slideUp(200)
          }, 150)
        }
      )

      //导航绿色标题高度处理
      $('.submenu dl').each(function () {
        var dl_h = $(this).height()
        $(this).find('dt').height(dl_h)
      })

      //首页短信、im、语音、视频动画效果
      $('.box1 ul li').hover(
        function () {
          $(this).find('.unhover').find('i').animate({ bottom: -145, opacity: '0' }, 500)
          $(this).find('.unhover').find('.txt').animate({ left: -125, opacity: '0' }, 500)
          $(this).find('.hover').show().find('i').animate({ top: 0 }, 500)
          $(this).find('.hover').show().find('.txt').animate({ right: 0 }, 500)
        },
        function () {
          $(this).find('.unhover').find('i').animate({ bottom: 0, opacity: '1' }, 500)
          $(this).find('.unhover').find('.txt').animate({ left: 0, opacity: '1' }, 500)
          $(this).find('.hover').find('i').animate({ top: -125 }, 500)
          $(this).find('.hover').find('.txt').animate({ right: -110 }, 500)
        }
      )

      //首页“简单”、“可靠”、“专注”、“全球”动画效果
      var current = $('.index_2 span.txt.current').index()
      $('.index_2 span.txt')
        .not('.current')
        .hover(
          function () {
            var span_index = $(this).index()
            $(this).addClass('current')
            $('.txt_desc').find('span').eq(span_index).fadeIn(800).siblings('span').fadeOut(800)
          },
          function () {
            var span_index = $(this).index()
            $(this).removeClass('current')
            $('.txt_desc').find('span').eq(span_index).fadeOut(800).siblings('span').eq(current).fadeIn(800)
          }
        )

      //首页客户图标鼠标放上去状态变化
      $('.index_4 ul li').hover(
        function () {
          var img_src = $(this).find('img').attr('src')
          var img_name = img_src.substring(7).replace('.png', '')
          //alert(img_name);
          $(this)
            .find('img')
            .attr('src', '/front/' + img_name + '_hover.png')
        },
        function () {
          var img_src = $(this).find('img').attr('src')
          var img_name = img_src.substring(7).replace('_hover.png', '')
          $(this)
            .find('img')
            .attr('src', '/front/' + img_name + '.png')
        }
      )

      //语音通知手风琴效果
      $('.voice_2 ul li').each(function () {
        var fold = $(this).find('.fold')
        var unfold = $(this).find('.unfold')
        if (fold.is(':hidden')) {
          $(this).width(445)
        } else {
          $(this).width(200)
        }
      })

      $('.voice_2 ul li').click(function () {
        var li_index = $(this).index()
        $(this).animate({ width: 445 }, 200)
        $(this).find('.unfold').show()
        $(this).find('.fold').hide()
        $(this).siblings().animate({ width: 200 }, 200)
        $(this).siblings().find('.unfold').hide()
        $(this).siblings().find('.fold').show()
      })

      //下拉框处理
      $('div.select_box ul li:even').css('background', '#f5f5f5')

      $('div.select_box').click(function (e) {
        if ('readonly' == $(this).attr('readonly')) {
          return false
        }
        e.stopPropagation()
        $(this).children('ul').toggle()
        $(this).toggleClass('focus')
      })
    })
  }
  getAdd() {
    console.log(this)
    let name = this.refs.name.state.value
    let phone = this.refs.phone.state.value
    let teamName = this.refs.teamName.state.value
    let type = this.refs.type.state.value
    console.log(name, phone, teamName, type)
    this.setState({ isloading: true })

    if (name !== undefined && name !== '') {
      if (phone !== undefined && phone !== '') {
        if (teamName !== undefined && teamName !== '') {
          if (type !== undefined && type !== '') {
            setTimeout(() => {
              this.setState({ isloading: false })

              message.success('提交成功')
            }, 1000)
          } else {
            setTimeout(() => {
              message.error('请输入职务')
              this.setState({ isloading: false })
            }, 1000)
          }
        } else {
          setTimeout(() => {
            message.error('请输入企业名称')
            this.setState({ isloading: false })
          }, 1000)
        }
      } else {
        setTimeout(() => {
          message.error('请输入手机号')
          this.setState({ isloading: false })
        }, 1000)
      }
    } else {
      setTimeout(() => {
        message.error('请输入姓名')
        this.setState({ isloading: false })
      }, 1000)
    }
  }
  toipt() {
    window.location.hash = '#abc'
  }
  //渲染渲染渲染渲染渲染渲染渲染渲染渲染渲染渲染渲染渲染渲染渲染渲染渲染渲染渲染渲染渲染渲染渲染渲染渲染渲染渲染渲染渲染渲染渲染
  render() {
    const {
      shujuyuanList, //企业大数据源
      zhengheList, //整合
      blueList, //
      greeList,
      teamList,
      isloading,
    } = this.state

    //企业大数据源
    let shujuyuan = shujuyuanList.map((obj, index) => (
      <Col key={index} span={6}>
        <QueueAnim type={['bottom', 'top']} leaveReverse={true} forcedReplay={true} delay={10 + index * 100}>
          <div key={index} className="flexColumn flexJCenter">
            <div style={{ width: '58px', height: '58px' }}>
              <img
                src={obj.img}
                alt="辽宁便利电科技"
                style={{
                  width: obj.size.w > obj.size.h ? '100%' : 'auto',
                  height: obj.size.w > obj.size.h ? 'auto' : '100%',
                }}
              />
            </div>
            <div
              style={{
                fontSize: '17px',
                color: '#000',
                marginTop: '20px',
                marginBottom: '5px',
              }}
            >
              {obj.title}
            </div>
            <div style={{ fontSize: '12px', color: '#4a4a4a' }}>{obj.tips}</div>
            <div style={{ fontSize: '12px', color: '#4a4a4a' }}>{obj.tips2}</div>
          </div>
        </QueueAnim>
      </Col>
    ))
    //整合
    let zhenghe = zhengheList.map((obj, index) => (
      <Col key={index} span={6}>
        <QueueAnim type={obj.aniType} leaveReverse={true} forcedReplay={true} delay={10 + obj.delay} duration={900}>
          <div key={index} className="flexColumn flexJCenter" style={{ position: 'relative', left: obj.left }}>
            <img src={obj.img} alt="辽宁便利电科技" style={{ width: '58px', height: '58px' }} />
            <div style={{ fontSize: '17px', color: '#000', marginTop: '10px' }}>{obj.title}</div>
          </div>
        </QueueAnim>
      </Col>
    ))
    const sty = {
      // backgroundColor: '#fff',
      paddingTop: '20px',
      paddingBottom: '90px',
      backgroundImage: `url(${Background})`,
      // backgroundSize: '100% 100%',
    }
    const sty2 = {
      backgroundImage: `url(${Background2})`,
    }
    const dengbg = {
      backgroundImage: `url(${Background3})`,
      height: '817px',
    }
    const dengbg2 = {
      backgroundImage: `url(${Background4})`,
      height: '722px',
    }
    const dengbg5 = {
      backgroundImage: `url(${Background5})`,
      height: '722px',
    }
    //domdomdomdomdomdomdomdomdomdomdomdomdomdomdomdomdomdomdomdomdomdomdomdomdomdomdomdomdomdomdomdomdomdomdom
    return (
      <div className="newretail_page">
        <PageBg
          bgSrc={require('../../static/images/S2b2C/SaaSbg3.png')}
          titleM={
            <>
              <p
                style={{
                  fontSize: '52px',
                  textAlign: 'center',
                  marginTop: '50px',
                }}
              >
                全渠道小程序新零售解决方案
              </p>
              <p
                style={{
                  fontSize: '22px',
                  textAlign: 'center',
                  color: 'rgba(255,255,255,1)',
                  marginTop: '15px',
                }}
              >
                助力企业构建私域流量进入品牌新零售新时代
              </p>
              <p style={{ textAlign: 'center' }} onClick={this.toipt}>
                <button
                  style={{
                    width: '173px',
                    height: '50px',
                    fontSize: '15px',
                    borderRadius: '8px',
                    fontFamily: 'PingFangSC-Thin,PingFang SC',
                    fontWeight: '100',
                    color: 'rgba(255,255,255,1)',
                    lineHeight: '36px',
                    background: 'none',
                    border: '1px solid rgba(255,255,255,1)',
                    margin: '0 auto',
                  }}
                >
                  联系商务
                </button>
              </p>
            </>
          }
        />

        {/* 整合 */}
        <div className="zhenghe overhidden common_item_wrap">
          <div className="zhenghe overhidden common_item_wrap" style={{ background: '#ffffff' }}>
            <div
              className="flexColumn newretail_titlewrap"
              style={{
                marginBottom: '50px',
              }}
            >
              <div className="newretail_titlewrap_title" style={{ marginTop: '30px' }}>
                产品中心
              </div>
            </div>
            <div
              className="common_wrap flexCenter flexBetween"
              style={{
                margin: '96px auto',
                height: '479px',
              }}
            >
              <div className="xiaochengxuleft1" />
              <div className="xiaochengxuright2">
                <p
                  style={{
                    marginTop: '84px',
                    textAlign: 'center',
                    fontSize: '22px',
                    marginBottom: '17px',
                  }}
                >
                  云商城
                </p>
                <p
                  style={{
                    textAlign: 'center',
                    fontSize: '19px',
                    fontWeight: '400',
                    marginBottom: '11px',
                  }}
                >
                  为企业实现人人都是销售员，实现全员营销传播
                </p>
                <p
                  style={{
                    textAlign: 'center',
                    fontSize: '19px',
                    marginBottom: '77px',
                    fontWeight: '400',
                  }}
                >
                  商品分享丨下单支付丨订单查询等
                </p>
                <div style={{ width: '116px', height: '116px', margin: '0 auto' }}>
                  <img style={{ width: '116px', height: '116px' }} src={require('../../static/images/S2b2C/成单小店mp_icon.png')} alt="辽宁便利电科技" />
                </div>
                <p
                  style={{
                    fontSize: '14px',
                    textAlign: 'center',
                    marginTop: '16px',
                  }}
                >
                  扫码体验小程序
                </p>
                <p
                  style={{
                    fontSize: '11px',
                    textAlign: 'center',
                    marginTop: '8px',
                  }}
                >
                  美意优选-由云商城生成
                </p>
              </div>
            </div>
          </div>

          {/* <div className="zhenghe overhidden common_item_wrap" style={dengbg2}>
            <div
              className="common_wrap flexCenter flexBetween"
              style={{
                margin: '96px auto',
                height: '479px',
              }}
            >
              <div className="xiaochengxuleft3" />
              <div className="xiaochengxuright3">
                <p
                  style={{
                    marginTop: '84px',
                    textAlign: 'center',
                    fontSize: '22px',
                    marginBottom: '17px',
                  }}
                >
                  云派工
                </p>
                <p
                  style={{
                    textAlign: 'center',
                    fontSize: '19px',
                    fontWeight: '400',
                    marginBottom: '11px',
                  }}
                >
                  为灵活用工个人提供抢单，完成任务并记录佣金结算服务
                </p>
                <p
                  style={{
                    textAlign: 'center',
                    fontSize: '19px',
                    marginBottom: '77px',
                    fontWeight: '400',
                  }}
                >
                  扩大销量丨增加收入丨减少成本等
                </p>
                <div style={{ width: '116px', height: '116px', margin: '0 auto' }}>
                  <img
                    style={{
                      width: '116px',
                      height: '116px',
                    }}
                    src={require('../../static/images/S2b2C/lovelingicon.png')}
                    alt="辽宁便利电科技"
                  />
                </div>
                <p
                  style={{
                    fontSize: '14px',
                    textAlign: 'center',
                    marginTop: '16px',
                  }}
                >
                  扫码体验小程序
                </p>
                <p
                  style={{
                    fontSize: '11px',
                    textAlign: 'center',
                    marginTop: '8px',
                  }}
                >
                  爱灵工小程序-由云派工生成
                </p>
              </div>
            </div>
          </div> */}

          <div className="zhenghe overhidden common_item_wrap" style={{ background: '#fff', color: '#000' }}>
            <div
              className="common_wrap flexCenter flexBetween"
              style={{
                margin: '76px auto',
                height: '479px',
              }}
            >
              <div className="xiaochengxuright2">
                <p
                  style={{
                    marginTop: '84px',
                    textAlign: 'center',
                    fontSize: '22px',
                    marginBottom: '17px',
                  }}
                >
                  云学院
                </p>
                <p
                  style={{
                    textAlign: 'center',
                    fontSize: '19px',
                    fontWeight: '400',
                    marginBottom: '11px',
                    width: '540px',
                    marginLeft: '-50px',
                  }}
                >
                  为企业提供用户行为轨迹大数据分析，消费者意向，商机预测
                </p>
                <p
                  style={{
                    textAlign: 'center',
                    fontSize: '19px',
                    marginBottom: '77px',
                    fontWeight: '400',
                  }}
                >
                  编辑名片丨资料展示丨数据统计等
                </p>
                <div style={{ width: '116px', height: '116px', margin: '0 auto' }}>
                  <img style={{ width: '116px', height: '116px' }} src={require('../../static/images/S2b2C/沃联推广mp_icon.png')} alt="辽宁便利电科技" />
                </div>
                <p
                  style={{
                    fontSize: '14px',
                    textAlign: 'center',
                    marginTop: '16px',
                  }}
                >
                  扫码体验小程序
                </p>
                <p
                  style={{
                    fontSize: '11px',
                    textAlign: 'center',
                    marginTop: '8px',
                  }}
                >
                  安全服务宝-由云学院生成
                </p>
              </div>
              <div className="xiaochengxuleft6" />
            </div>
          </div>
          {/* <div className="zhenghe overhidden common_item_wrap" style={dengbg5}>
            <div
              className="common_wrap flexCenter flexBetween"
              style={{
                margin: '96px auto',
                height: '479px',
              }}
            >
              <div className="xiaochengxuleft4" />
              <div className="xiaochengxuright3">
                <p
                  style={{
                    marginTop: '84px',
                    textAlign: 'center',
                    fontSize: '22px',
                    marginBottom: '17px',
                  }}
                >
                  云结算
                </p>
                <p
                  style={{
                    textAlign: 'center',
                    fontSize: '19px',
                    fontWeight: '400',
                    marginBottom: '11px',
                  }}
                >
                  为用户提供实名认证、签约与结算服务
                </p>
                <p
                  style={{
                    textAlign: 'center',
                    fontSize: '19px',
                    marginBottom: '77px',
                    fontWeight: '400',
                  }}
                >
                  实名认证丨领取任务丨佣金记录
                </p>
                <div style={{ width: '116px', height: '116px', margin: '0 auto' }}>
                  <img
                    style={{
                      width: '116px',
                      height: '116px',
                    }}
                    src={require('../../static/images/S2b2C/leicon.png')}
                    alt="辽宁便利电科技"
                  />
                </div>
                <p
                  style={{
                    fontSize: '14px',
                    textAlign: 'center',
                    marginTop: '16px',
                  }}
                >
                  扫码体验小程序
                </p>
                <p
                  style={{
                    fontSize: '11px',
                    textAlign: 'center',
                    marginTop: '8px',
                  }}
                >
                  乐薪-由云结算生成
                </p>
              </div>
            </div>
          </div> */}

          <div className="zhenghe overhidden common_item_wrap" style={{ background: '#fff', color: '#000' }}>
            <div
              className="common_wrap flexCenter flexBetween"
              style={{
                margin: '76px auto',
                height: '479px',
              }}
            >
              <div className="xiaochengxuright2">
                <p
                  style={{
                    marginTop: '84px',
                    textAlign: 'center',
                    fontSize: '22px',
                    marginBottom: '17px',
                  }}
                >
                  云名片
                </p>
                <p
                  style={{
                    textAlign: 'center',
                    fontSize: '19px',
                    fontWeight: '400',
                    marginBottom: '11px',
                  }}
                >
                  为企业产品与资料信息提供宣传与推广服务
                </p>
                <p
                  style={{
                    textAlign: 'center',
                    fontSize: '19px',
                    marginBottom: '77px',
                    fontWeight: '400',
                  }}
                >
                  编辑名片丨资料展示丨数据统计等
                </p>
                <div style={{ width: '116px', height: '116px', margin: '0 auto' }}>
                  <img style={{ width: '116px', height: '116px' }} src={require('../../static/images/S2b2C/jiesuanicon.png')} alt="辽宁便利电科技" />
                </div>
                <p
                  style={{
                    fontSize: '14px',
                    textAlign: 'center',
                    marginTop: '16px',
                  }}
                >
                  扫码体验小程序
                </p>
                <p
                  style={{
                    fontSize: '11px',
                    textAlign: 'center',
                    marginTop: '8px',
                  }}
                >
                  节算宝名片-由云名片生成
                </p>
              </div>
              <div className="xiaochengxuleft2" />
            </div>
          </div>

          <div className="zhenghe overhidden common_item_wrap" style={{ backgroundColor: '#fff' }}>
            <div className="flexColumn newretail_titlewrap" style={{ marginBottom: '35px' }}>
              <div className="newretail_titlewrap_title">服务客户</div>
              <div className="newretail_titlewrap_descp">目前节算宝共享经济综合服务平台的签约合作客户涵盖通信、金融、家电、零售和互联网等多个行业</div>
            </div>
            <div
              className="common_wrap flexBetween SaaS_SaaS_teamList_item_wrap"
              style={{
                margin: '76px auto',
                height: '230px',
                width: '1076px',
              }}
            >
              {teamList.map((item, ind) => (
                <div key={ind} className="SaaS_SaaS_teamList_item">
                  <img style={item.imgsty} src={item.url} alt="辽宁便利电科技" />
                </div>
              ))}
            </div>
          </div>

          <div className="zhenghe overhidden common_item_wrap" style={{ backgroundColor: '#F7F7F7' }}>
            <div className="flexColumn newretail_titlewrap" style={{ marginBottom: '35px' }}>
              <div className="newretail_titlewrap_title">便利电邀您共拓万亿蓝海市场</div>
              <div className="newretail_titlewrap_descp">留下您的联系方式，我们将有专人与您联系</div>
            </div>
            <div
              className="common_wrap  flexBetween"
              style={{
                margin: '76px auto',
                height: '118px',
                width: '1076px',
              }}
            >
              <div
                style={{
                  width: '45%',
                  height: '38px',
                  float: 'left',
                  marginRight: '32px',
                  marginBottom: '30px',
                }}
              >
                <Input ref="name" placeholder="姓名" />
              </div>
              <div
                style={{
                  width: '45%',
                  height: '38px',
                  float: 'right',
                  marginBottom: '30px',
                }}
              >
                <Input ref="phone" placeholder="电话" />
              </div>
              <div
                style={{
                  width: '45%',
                  height: '38px',
                  float: 'left',
                  marginRight: '32px',
                  marginBottom: '30px',
                }}
              >
                <Input ref="teamName" placeholder="企业名称" />
              </div>
              <div
                style={{
                  width: '45%',
                  height: '38px',
                  float: 'right',
                  marginBottom: '30px',
                }}
              >
                <Input ref="type" placeholder="职务" />
              </div>
              <div
                style={{
                  width: '45%',
                  height: '38px',
                  float: 'right',
                  marginBottom: '30px',
                }}
              >
                <Button
                  style={{
                    width: '103px',
                    height: '38px',
                    background: '#F66F6A',
                    color: '#fff',
                    float: 'right',
                  }}
                  onClick={(e) => this.getAdd()}
                  loading={isloading}
                >
                  提交
                </Button>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}
