import React, { Component } from 'react'
import { Icon } from 'antd'
import Global from '../../Global'
import './Carousel3d2.less'

const wrapheight = 330

export default class Carousel3d extends Component {
  constructor(props) {
    super(props)
    this.state = {
      classList: ['p1', 'p2', 'p3', 'p4', 'p5', 'p6', 'p7', 'p8', 'p9', 'p10', 'p11', 'p12', 'p13'],
      // scaleList: [0.6705, 0.8011, 0.8693, 0.9318, 1, 0.9318, 0.8693, 0.8011, 0.6705],
      centerIndex: 0, //一共展示9个，初始中间的是第5个,itemList的index为4
    }
  }
  clickLeft = () => {
    //修改classList
    let list = this.state.classList
    let item = list.shift()
    list.push(item)
    //修改centerIndex
    let centerIndex
    if (this.state.centerIndex === 0) {
      centerIndex = this.props.itemList.length - 1
    } else {
      centerIndex = this.state.centerIndex - 1
    }

    this.setState({
      classList: list,
      centerIndex: centerIndex,
    })
  }
  clickRight = () => {
    //修改classList
    let list = this.state.classList
    let item = list.pop()
    list.unshift(item)
    //修改centerIndex
    let centerIndex
    if (this.state.centerIndex === this.props.itemList.length - 1) {
      centerIndex = 0
    } else {
      centerIndex = this.state.centerIndex + 1
    }
    console.log(centerIndex)
    this.setState({
      classList: list,
      centerIndex: centerIndex,
    })
  }
  clickOne = (idx, e) => {
    // console.log(e.target)
    if (idx === 4) {
      const { itemList } = this.props
      Global.tupianFangda(e.target, itemList, 'lg', 4)
    } else {
      //点击两侧的，直接将这个img跳到中间
      console.log('跳到这个', idx, e.target)
      //修改classList
      let list = this.state.classList
      if (idx < 4) {
        //点击的是左侧
        new Array(4 - idx).fill(0).forEach(function () {
          let item = list.shift()
          list.push(item)
        })
      } else if (idx > 4) {
        new Array(idx - 4).fill(0).forEach(function () {
          let item = list.pop()
          list.unshift(item)
        })
      }
      // console.log(list)
      //修改centerIndex
      this.setState({
        classList: list,
        centerIndex: Number(e.target.getAttribute('data-imglistindex')),
      })
    }
  }
  render() {
    const { itemList } = this.props
    // console.log(itemList)
    //得到展示的9个图片
    let showList
    let list1 //前面4个
    // let list2 //中间自己
    let list3 //后面4个
    //前面4个
    // console.log(this.state.centerIndex)
    if (this.state.centerIndex - 4 >= 0) {
      list1 = itemList.slice(this.state.centerIndex - 4, this.state.centerIndex)
    } else {
      let list1temp1 = itemList.slice(itemList.length - (4 - (this.state.centerIndex - 0)))
      let list1temp2 = itemList.slice(0, this.state.centerIndex)
      // console.log(list1temp1, list1temp2)
      list1 = [...list1temp1, ...list1temp2]
    }
    //后面4个
    if (this.state.centerIndex + 4 <= itemList.length - 1) {
      list3 = itemList.slice(this.state.centerIndex + 1, this.state.centerIndex + 5)
    } else {
      let list3temp1 = itemList.slice(this.state.centerIndex + 1)
      let list3temp2 = itemList.slice(0, this.state.centerIndex + 4 - (itemList.length - 1))
      list3 = [...list3temp1, ...list3temp2]
    }
    // console.log(list1)
    // console.log(list3)
    showList = [...list1, itemList[this.state.centerIndex], ...list3]
    // console.log(showList)
    //得到展示的9个图片 end
    let imgDiv = this.state.classList.map((str, index) => {
      let showImgIndex = Number(str[1]) - 1
      let imgObj = showList[showImgIndex]
      // console.log(imgObj);
      return (
        <div key={index} className="cItem flexColumn">
          <div className={'imgWrap flexColumn ' + str} style={{ width: 1000, height: 500 }}>
            <img
              src={imgObj && imgObj.sm ? imgObj.sm : ''}
              alt="辽宁便利电科技"
              data-lgimg={imgObj && imgObj.lg}
              data-imglistindex={imgObj && imgObj.imgListIndex}
              onClick={(e) => {
                this.clickOne(showImgIndex, e)
              }}
            />
            {/* <div>{imgObj.title}</div> */}
          </div>
        </div>
      )
    })
    return (
      <div className="carousel3d">
        <div className="flexCenter flexJCenter">
          <div
            className="carousel3d_leftBtn flexCenter flexJCenter posiRe"
            style={{ width: 100, height: 100, borderRadius: '100%', background: '#fff', minHeight: 100, marginTop: 200, marginRight: 50, color: '#EA4336', fontSize: 30 }}
            onClick={this.clickLeft}
          >
            <Icon type="left" />
          </div>
          <div className="wrap" style={{ height: wrapheight, maxWidth: 1200 }}>
            {imgDiv}
          </div>
          <div
            className="carousel3d_rightBtn flexCenter flexJCenter posiRe"
            style={{ width: 100, height: 100, borderRadius: '100%', background: '#fff', minHeight: 100, marginTop: 200, marginLeft: 50, color: '#EA4336', fontSize: 30 }}
            onClick={this.clickRight}
          >
            <Icon type="right" />
          </div>
        </div>
        <div style={{ textAlign: 'center', color: '#4A4A4A', fontSize: '12px', marginBottom: 50 }}>{/* {this.props.itemList[this.state.centerIndex].title} */}</div>
      </div>
    )
  }
}
