// 请求域名
export const allHostObj = {
  // devHost: { text: '测试', host: 'http://1.2.4.219:8488' }, //刘
  // devHost: { text: '测试', host: 'http://1.2.4.240:8488' }, //婷
  // devHost: { text: '测试', host: 'http://1.2.4.137:8488' }, //鹏
  // devHost: { text: '测试', host: 'http://1.2.4.176:8488' }, //浩
  // devHost: { text: '测试', host: 'http://1.2.4.61:8488' }, //张志斌
  // devHost: { text: '测试', host: 'https://lubanweb.bld365.com' }, //生产
  devHost: { text: '测试', host: 'https://lubantweb.bld365.com' }, //真正的测试地址
  proHost: { text: '生产', host: 'https://lubanweb.bld365.com' },
  proHostPor: { text: '生产', host: 'https://lubanwebpre.bld365.com' },
}

// const url =  window.location.href;
// const prodHostArr=[]
// if (url.indexOf(['//luban.bld365.com/']) > -1) {
//   prodHostArr[0]='//luban.bld365.com/'
// }else {
//   prodHostArr[0]='//lubanpre.bld365.com/'
// }
// export default  prodHostArr
// 前端代码部署的生产域名
export const prodHostArr = ['//luban.bld365.com/', '//lubanpre.bld365.com/']

// loginState在localStorage的key
export const loginStateKey = 'gree-loginState'
