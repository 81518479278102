/**
 * 用于更新state的reducer，只有一个SAVE方法
 */
import { defaultState } from '../models/ad'

const reducer = (state = defaultState, action) => {
  switch (action.type) {
    case 'SAVE':
      let stateTemp = {
        ...state,
        ...action.payload,
      }
      // console.log(JSON.stringify(stateTemp))
      localStorage.setItem('bldweb', JSON.stringify(stateTemp))
      return stateTemp
    default:
      return state
  }
}

export default reducer
