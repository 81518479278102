/* eslint-disable */
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { adActions } from '../../redux/models/ad'
import { Link, withRouter } from 'react-router-dom'
import { Row, Col } from 'antd'
import './Header.less'

class Header extends Component {
  constructor(props) {
    super(props)
    this.state = {
      //透明度
      headerOpacity: 0,

      tabList: [
        { text: '首页', link: '/' },
        { text: '产品中心', link: '/SaaS' },
        { text: '行业应用', link: '/jianliu' },
        { text: '数据中台', link: '/newretail' },
        { text: '关于我们', link: '/aboutus' },
        { text: '企业资质', link: '/qualification' },
      ],
    }
  }
  //周期周期周期周期周期周期周期周期周期周期周期周期周期周期周期周期周期周期周期周期周期周期
  componentDidMount() {
    window.addEventListener('scroll', this.handleScroll)
  }
  //方法方法方法方法方法方法方法方法方法方法方法方法方法方法方法方法方法方法方法方法方法方法方法
  //滚动
  handleScroll = () => {
    let carouselHeight
    if (document.getElementsByClassName('ant-carousel')[0]) {
      carouselHeight = document.getElementsByClassName('ant-carousel')[0].clientHeight
    } else {
      carouselHeight = document.getElementsByClassName('img_wrap')[0].clientHeight
    }
    let srcollTop = document.documentElement.scrollTop || window.pageYOffset || document.body.scrollTop
    //修改header透明度
    let newOpacity = srcollTop / carouselHeight
    this.setState({ headerOpacity: newOpacity })
  }
  //点击导航
  clickTab = (idx) => {
    const { dispatch } = this.props
    dispatch(adActions.changeTabIndex(idx))
  }

  toHome = () => {
    this.props.history.go(-2)
  }
  //渲染渲染渲染渲染渲染渲染渲染渲染渲染渲染渲染渲染渲染渲染渲染渲染渲染渲染渲染渲染渲染渲染渲染渲染渲染渲染渲染
  render() {
    const { headerOpacity, tabList } = this.state
    const { ad } = this.props
    let logoSrc = require('../../static/images/LOGO_new.svg')
    let tabs = tabList.map((obj, index) => {
      return (
        <Link
          key={index}
          className={(headerOpacity > 0.7 ? 'black' : headerOpacity < 0.2 ? 'black' : 'white') + (index === ad.curTabIndex ? ' active' : '')}
          onClick={() => {
            this.clickTab(index)
          }}
          to={obj.link}
        >
          {obj.text}
        </Link>
      )
    })

    window.addEventListener(
      'popstate',
      function (e) {
        console.log(e, '回退了')
        if (e.state) {
          //侦测是用户触发的后退操作, dosomething
          //这里刷新当前url
          this.location.reload()
        }
      },
      false
    )
    //domdomdomdomdomdomdomdomdomdomdomdomdomdomdomdomdomdomdomdomdomdomdomdomdomdomdomdomdomdomdom
    return (
      <div
        className="header"
        style={{
          backgroundColor: 'rgba(255, 255, 255, ' + headerOpacity + ')',
        }}
      >
        <Row type="flex" align="middle" justify="space-between" style={{ minWidth: '710px' }}>
          <Col sm={{ span: 3, offset: 0 }} md={{ span: 4, offset: 1 }} lg={{ span: 6, offset: 1 }} xl={{ span: 9, offset: 2 }} xxl={{ span: 10, offset: 2 }}>
            <img className="header_logo" src={logoSrc} onClick={this.toHome} alt="辽宁便利电科技" />
          </Col>
          <Col sm={{ span: 21, offset: 0 }} md={{ span: 19, offset: 0 }} lg={{ span: 17, offset: 0 }} xl={{ span: 13, offset: 0 }} xxl={{ span: 12, offset: 0 }}>
            <div className="link_wrap flexCenter">{tabs}</div>
          </Col>
        </Row>
      </div>
    )
  }
}

const mapStateToProps = (state) => ({
  //选择注入哪些state，state 所有的state(reducer)
  ad: state.ad,
})

export default connect(mapStateToProps)(withRouter(Header))
