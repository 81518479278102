/* eslint-disable */
import React, { Component } from 'react'
import { Row, Col } from 'antd'
import PageBg from '../../components/PageBg/PageBg'
import { mobileW } from '../../utils/utils'
import QueueAnim from 'rc-queue-anim'
import ScrollAnim from 'rc-scroll-anim'
import './Newretail.less'

const ScrollOverPack = ScrollAnim.OverPack

export default class Newretail extends Component {
  constructor(props) {
    super(props)
    this.state = {
      //企业大数据源
      shujuyuanList: [
        {
          title: '行为数据源',
          tips: '因用户个人个体行为而产生的数据',
          tips2: '门店客流、APP行为、客户评论等',
          img: require('../../static/images/newretail/shujuyuan/xwsjy.png'),
          size: { w: 125, h: 122 },
        },
        {
          title: '商业经营数据来源',
          tips: '围绕交易和内部管理系统运行产生的数据',
          tips2: '交易订单、供应链、智能管理等',
          img: require('../../static/images/newretail/shujuyuan/syjysjly.png'),
          size: { w: 128, h: 128 },
        },
        {
          title: '技术运维数据源',
          tips: '在技术产品运行过程中产生的数据',
          tips2: '云监控、运行日志、系统异常等',
          img: require('../../static/images/newretail/shujuyuan/jsywsjy.png'),
          size: { w: 131, h: 116 },
        },
        {
          title: '第三方数据源',
          tips: '第三方公司所公开提供的一些数据',
          tips2: '腾讯微信、国家统计局、网络应用等',
          img: require('../../static/images/newretail/shujuyuan/dsfsjy.png'),
          size: { w: 125, h: 92 },
        },
      ],
      //整合
      zhengheList: [
        {
          title: '客户触点',
          img: require('../../static/images/newretail/zhenghe/khcd.png'),
          left: -67, //控制位置
          aniType: ['left', 'right'],
          delay: 100,
        },
        {
          title: '门店触点',
          img: require('../../static/images/newretail/zhenghe/mdcd.png'),
          left: -39, //控制位置
          aniType: ['left', 'right'],
          delay: 0,
        },
        {
          title: '总部触点',
          img: require('../../static/images/newretail/zhenghe/zbcd.png'),
          left: 64, //控制位置
          aniType: ['right', 'left'],
          delay: 100,
        },
        {
          title: '数字触点',
          img: require('../../static/images/newretail/zhenghe/szcd.png'),
          left: 65, //控制位置
          aniType: ['right', 'left'],
          delay: 200,
        },
      ],
    }
  }
  //周期周期周期周期周期周期周期周期周期周期周期周期周期周期周期周期周期周期周期周期周期周期周期周期周期周期周期周期
  componentDidMount() {
    window.scrollTo(0, 0)
  }
  //渲染渲染渲染渲染渲染渲染渲染渲染渲染渲染渲染渲染渲染渲染渲染渲染渲染渲染渲染渲染渲染渲染渲染渲染渲染渲染渲染渲染渲染渲染渲染
  render() {
    const {
      shujuyuanList, //企业大数据源
      zhengheList, //整合
    } = this.state

    //企业大数据源
    let shujuyuan = shujuyuanList.map((obj, index) => (
      <Col key={index} span={6}>
        <QueueAnim type={['bottom', 'top']} leaveReverse={true} forcedReplay={true} delay={10 + index * 100}>
          <div key={index} className="flexColumn flexJCenter">
            <div style={{ width: '58px', height: '58px' }}>
              <img
                src={obj.img}
                alt="辽宁便利电科技"
                style={{
                  width: obj.size.w > obj.size.h ? '100%' : 'auto',
                  height: obj.size.w > obj.size.h ? 'auto' : '100%',
                }}
              />
            </div>
            <div
              style={{
                fontSize: '17px',
                color: '#000',
                marginTop: '20px',
                marginBottom: '5px',
              }}
            >
              {obj.title}
            </div>
            <div style={{ fontSize: '12px', color: '#4a4a4a' }}>{obj.tips}</div>
            <div style={{ fontSize: '12px', color: '#4a4a4a' }}>{obj.tips2}</div>
          </div>
        </QueueAnim>
      </Col>
    ))
    //整合
    let zhenghe = zhengheList.map((obj, index) => (
      <Col key={index} span={6}>
        <QueueAnim type={obj.aniType} leaveReverse={true} forcedReplay={true} delay={10 + obj.delay} duration={900}>
          <div key={index} className="flexColumn flexJCenter" style={{ position: 'relative', left: obj.left }}>
            <img src={obj.img} alt="辽宁便利电科技" style={{ width: '58px', height: '58px' }} />
            <div style={{ fontSize: '17px', color: '#000', marginTop: '10px' }}>{obj.title}</div>
          </div>
        </QueueAnim>
      </Col>
    ))
    //domdomdomdomdomdomdomdomdomdomdomdomdomdomdomdomdomdomdomdomdomdomdomdomdomdomdomdomdomdomdomdomdomdomdom
    return (
      <div className="newretail_page">
        <PageBg
          bgSrc={require('../../static/images/newretail_bg.png')}
          titleM="通过大数据，全面掌控局势"
          descp="从完成当月的目标到参与未来交易预测，保证生意始终符合您的预期"
          contentStyle={{
            textAlign: 'center',
          }}
        />

        {/* 企业大数据源 */}
        <div
          className="shujuyuan overhidden common_item_wrap"
          style={{
            backgroundColor: '#fff',
            paddingTop: '20px',
            paddingBottom: '90px',
          }}
        >
          <div className="flexColumn newretail_titlewrap" style={{ marginBottom: '50px' }}>
            <div className="newretail_titlewrap_title">企业大数据源</div>
            <div className="newretail_titlewrap_descp">企业数据源分用户行为、经营管理、技术运维、第三方四个维度</div>
          </div>
          {window.screen.width > mobileW ? (
            <div style={{ height: '158px' }}>
              <ScrollOverPack playScale={[0.15, 0.15]}>
                <Row type="flex" className="common_wrap">
                  {shujuyuan}
                </Row>
              </ScrollOverPack>
            </div>
          ) : (
            <div style={{ height: '158px' }}>
              <Row type="flex" className="common_wrap">
                {shujuyuan}
              </Row>
            </div>
          )}
        </div>

        {/* 关系图 */}
        <div className="guanxi overhidden common_item_wrap" style={{ backgroundColor: '#30383E' }}>
          <div className="flexColumn newretail_titlewrap">
            <div className="newretail_titlewrap_title" style={{ color: '#fff' }}>
              大数据应用关系图
            </div>
            <div className="newretail_titlewrap_descp" style={{ color: '#fff' }}>
              把大数据划分为运营、产品、技术、再看它的应用价值
            </div>
          </div>
          <Row
            type="flex"
            align="middle"
            justify="space-between"
            className="common_wrap guanxi_wrap"
            style={{
              width: '1189px',
              height: '526px',
              zoom: '0.8',
            }}
          >
            <div className="guanxi_container">
              <div className="step1">
                <div style={{ fontSize: '16px' }}>Report</div>
                <div style={{ fontSize: '12px' }}>配合工具生成报告</div>
              </div>
              <div className="step1">
                <div style={{ fontSize: '16px' }}>数据秘书</div>
                <div style={{ fontSize: '12px' }}>小程序或APP快速查看</div>
                <div style={{ fontSize: '12px' }}>对应角色的数据看板</div>
              </div>
            </div>
            <div className="guanxi_container">
              <div className="step2">
                <div style={{ fontSize: '16px' }}>数据集市</div>
                <div style={{ fontSize: '12px' }}>用户标签、用户画像</div>
              </div>
              <div className="step2">
                <div style={{ fontSize: '16px' }}>BI系统</div>
                <div style={{ fontSize: '12px' }}>数据可视化系统</div>
              </div>
              <div className="step2">
                <div style={{ fontSize: '16px' }}>行为分析系统</div>
                <div style={{ fontSize: '12px' }}>用户行为埋点</div>
              </div>
              <div className="step2">
                <div style={{ fontSize: '16px' }}>运维日志系统</div>
              </div>
              <div className="step2">
                <div style={{ fontSize: '16px' }}>行智能推荐系统</div>
                <div style={{ fontSize: '12px' }}>基于海量外部数据的机器学习</div>
              </div>
            </div>
            <div className="guanxi_container" style={{ fontSize: '16px' }}>
              <div className="step3">数据物流</div>
              <div className="flex" style={{ padding: '0 40px' }}>
                <div className="flexColumn flexBetween" style={{ flex: '1 0 0' }}>
                  <div className="step3">数据湖</div>
                  <div className="step3">数据仓库</div>
                </div>
                <div className="flexColumn" style={{ flex: '1 0 0' }}>
                  <div className="step3">订单数据</div>
                  <div className="step3">用户数据</div>
                  <div className="step3">商品数据</div>
                  <div className="step3">门店数据</div>
                  <div className="step3">供应链数据</div>
                  <div className="step3">财务数据</div>
                  <div className="step3">第三方数据</div>
                </div>
              </div>
            </div>
          </Row>
          <div
            className="common_wrap flexCenter flexBetween"
            style={{
              color: 'rgba(255,255,255,0.8)',
              padding: '0 152px',
              margin: '20px auto 35px',
            }}
          >
            <div>数据运营</div>
            <div>数据产品</div>
            <div>数据技术</div>
          </div>
        </div>

        {/* 整合 */}
        <div className="zhenghe overhidden common_item_wrap" style={{ backgroundColor: '#fff' }}>
          <div className="flexColumn newretail_titlewrap" style={{ marginBottom: '55px' }}>
            <div className="newretail_titlewrap_title">企业的科技化在于角色、领域、流程的整合</div>
            <div className="newretail_titlewrap_descp">与单点突破不同，产业互联网的关键在于整合</div>
          </div>
          {window.screen.width > mobileW ? (
            <div style={{ height: '96px' }}>
              <ScrollOverPack playScale={[0.15, 0.15]}>
                <Row type="flex" className="common_wrap">
                  {zhenghe}
                </Row>
              </ScrollOverPack>
            </div>
          ) : (
            <div style={{ height: '96px' }}>
              <Row type="flex" className="common_wrap">
                {zhenghe}
              </Row>
            </div>
          )}

          {/* 整合关系图 */}
          <div className="common_wrap flexCenter flexBetween" style={{ margin: '36px auto' }}>
            {/* 客户触点 */}
            <div className="flexColumn flexBetween" style={{ height: '420px' }}>
              <div className="zhItem">电商平台</div>
              <div className="zhItem">O2O平台</div>
              <div className="zhItem">品牌APP</div>
              <div className="zhItem">品牌小程序</div>
              <div className="zhItem">品牌门店</div>
            </div>
            <div className="zhArrow" style={{ height: '360px' }}>
              <img className="img100" src={require('../../static/images/newretail/zhenghe/arrow1.png')} alt="辽宁便利电科技" />
            </div>
            {/* 门店触点 */}
            <div className="flexColumn flexBetween" style={{ width: '259px', height: '490px' }}>
              <div className="flexCenter flexBetween" style={{ flexWrap: 'wrap' }}>
                <div className="zhItem" style={{ marginBottom: '30px' }}>
                  订单管理
                </div>
                <div className="zhItem" style={{ marginBottom: '30px' }}>
                  门店管理
                </div>
                <div className="zhItem">货物管理</div>
                <div className="zhItem">效率管理</div>
              </div>
              {window.screen.width > mobileW ? (
                <div style={{ height: '96px' }}>
                  <ScrollOverPack playScale={[0.15, 0.15]}>
                    <QueueAnim type={['bottom', 'top']} leaveReverse={true} forcedReplay={true} delay={10} duration={900}>
                      <div className="flexColumn" key="1">
                        <img src={require('../../static/images/newretail/zhenghe/yxcd.png')} alt="辽宁便利电科技" style={{ width: '66px', height: '66px' }} />
                        <div
                          style={{
                            fontSize: '17px',
                            color: '#000',
                            marginTop: '10px',
                          }}
                        >
                          营销触点
                        </div>
                      </div>
                    </QueueAnim>
                  </ScrollOverPack>
                </div>
              ) : (
                <div style={{ height: '96px' }}>
                  <QueueAnim type={['bottom', 'top']} leaveReverse={true} forcedReplay={true} delay={10} duration={900}>
                    <div className="flexColumn" key="1">
                      <img src={require('../../static/images/newretail/zhenghe/yxcd.png')} alt="辽宁便利电科技" style={{ width: '66px', height: '66px' }} />
                      <div
                        style={{
                          fontSize: '17px',
                          color: '#000',
                          marginTop: '10px',
                        }}
                      >
                        营销触点
                      </div>
                    </div>
                  </QueueAnim>
                </div>
              )}
              <div className="flexCenter flexBetween" style={{ flexWrap: 'wrap' }}>
                <div className="zhItem" style={{ marginBottom: '30px' }}>
                  会员管理
                </div>
                <div className="zhItem" style={{ marginBottom: '30px' }}>
                  营销平台
                </div>
                <div className="zhItem">用户管理</div>
                <div className="zhItem">渠道管理</div>
              </div>
            </div>
            <div className="zhArrow" style={{ height: '360px' }}>
              <img className="img100" src={require('../../static/images/newretail/zhenghe/arrow2.png')} alt="辽宁便利电科技" />
            </div>
            {/* 总部触点 */}
            <div className="flexCenter flexBetween" style={{ width: '259px' }}>
              <div className="flexColumn flexBetween" style={{ height: '323px' }}>
                <div className="zhItem">人力资源管理</div>
                <div className="zhItem">商业智能</div>
                <div className="zhItem">项目管理</div>
                <div className="zhItem">流程管理</div>
              </div>
              <div className="flexColumn flexBetween" style={{ height: '323px' }}>
                <div className="zhItem">供应链管理</div>
                <div className="zhItem">行政管理</div>
                <div className="zhItem">财务管理</div>
                <div className="zhItem">培训管理</div>
              </div>
            </div>
            <div style={{ height: '19px', width: '59px' }}>
              <img className="img100" src={require('../../static/images/newretail/zhenghe/arrow3.png')} alt="辽宁便利电科技" />
            </div>
            {/* 数字触点 */}
            <div className="flexColumn flexBetween" style={{ height: '240px' }}>
              <div className="zhItem">IT设施管理</div>
              <div className="zhItem">大数据管理</div>
              <div className="zhItem">数字系统管理</div>
            </div>
          </div>

          <div className="common_wrap">
            <img className="imgw100" src={require('../../static/images/newretail/zhenghe/bigArrow.png')} alt="辽宁便利电科技" />
            <div
              className="flexCenter flexBetween"
              style={{
                fontSize: '14px',
                padding: '0 41px',
                margin: '10px auto 35px',
              }}
            >
              <div className="flexColumn">
                <div>C用户体验</div>
                <div>消费者</div>
              </div>
              <div className="flexColumn">
                <div>B业务流程</div>
                <div>智能人员</div>
              </div>
              <div className="flexColumn">
                <div>D大数据</div>
                <div>科技人员</div>
              </div>
            </div>
          </div>
          {/* 整合关系图 end */}
        </div>
      </div>
    )
  }
}
