/* eslint-disable */
import React, { Component } from 'react'
import { Cascader, Input, Button, message, Row, Col } from 'antd'
import PageBg from '../../components/PageBg/PageBg'
import Position from '../../components/Position'
import { mobileW } from '../../utils/utils'
import QueueAnim from 'rc-queue-anim'
import ScrollAnim from 'rc-scroll-anim'
import Carousel3d from '../../components/Carousel3d2/Carousel3d'
import './Aboutus.less'

const ScrollOverPack = ScrollAnim.OverPack
const { TextArea } = Input

export default class Aboutus extends Component {
  constructor(props) {
    super(props)
    this.state = {
      rightList: [
        {
          title: 'B2B交易管理系统',
          imgListIndex: 0,
          sm: require('../../static/images/yiyuan/yiyuan1.png'),
          lg: require('../../static/images/yiyuan/yiyuan1.png'),
        },
        {
          title: '充值缴费管理系统',
          imgListIndex: 1,
          sm: require('../../static/images/yiyuan/yiyuan2.png'),
          lg: require('../../static/images/yiyuan/yiyuan2.png'),
        },
        {
          title: '电信业务B2b2C分销管理',
          imgListIndex: 2,
          sm: require('../../static/images/yiyuan/yiyuan3.png'),
          lg: require('../../static/images/yiyuan/yiyuan3.png'),
        },
        {
          title: '和伙伴APP软件',
          imgListIndex: 3,
          sm: require('../../static/images/yiyuan/yiyuan4.png'),
          lg: require('../../static/images/yiyuan/yiyuan4.png'),
        },
        {
          title: '和伙人APP软件',
          imgListIndex: 4,
          sm: require('../../static/images/yiyuan/yiyuan5.png'),
          lg: require('../../static/images/yiyuan/yiyuan5.png'),
        },
        {
          title: '和小店APP软件',
          imgListIndex: 5,
          sm: require('../../static/images/yiyuan/yiyuan6.png'),
          lg: require('../../static/images/yiyuan/yiyuan6.png'),
        },
        {
          title: '零售店ERP管理系统',
          imgListIndex: 6,
          sm: require('../../static/images/yiyuan/yiyuan7.png'),
          lg: require('../../static/images/yiyuan/yiyuan7.png'),
        },
        {
          title: '码上办管理平台系统',
          imgListIndex: 7,
          sm: require('../../static/images/yiyuan/yiyuan8.png'),
          lg: require('../../static/images/yiyuan/yiyuan8.png'),
        },
        {
          title: '扫码购APP软件',
          imgListIndex: 8,
          sm: require('../../static/images/yiyuan/yiyuan9.png'),
          lg: require('../../static/images/yiyuan/yiyuan9.png'),
        },

        {
          title: '佣金结算系统',
          imgListIndex: 10,
          sm: require('../../static/images/yiyuan/yiyuan10.png'),
          lg: require('../../static/images/yiyuan/yiyuan10.png'),
        },
        {
          title: '佣金结算系统',
          imgListIndex: 10,
          sm: require('../../static/images/yiyuan/yiyuan11.png'),
          lg: require('../../static/images/yiyuan/yiyuan10.png'),
        },
        {
          title: '佣金结算系统',
          imgListIndex: 10,
          sm: require('../../static/images/yiyuan/yiyuan12.png'),
          lg: require('../../static/images/yiyuan/yiyuan10.png'),
        },
        {
          title: '佣金结算系统',
          imgListIndex: 10,
          sm: require('../../static/images/yiyuan/yiyuan13.png'),
          lg: require('../../static/images/yiyuan/yiyuan10.png'),
        },
      ],
      //服务
      fuwuList: [
        {
          title: '实名认证',
          tip: '官方在线、实时认证、标准SDK',
          imgSrc: require('../../static/images/home/fuwu/huanjing1.png'),
          // size: { w: 132, h: 96 },
        },
        {
          title: '业务受理',
          tip: '官方平台、实名制合规、全业务实时受理',
          imgSrc: require('../../static/images/home/fuwu/huanjing2.png'),
          // size: { w: 104, h: 122 },
        },
        {
          title: '数字化分销',
          tip: '线上推广裂变、大数据智慧导购',
          imgSrc: require('../../static/images/home/fuwu/huanjing3.png'),
          // size: { w: 122, h: 120 },
        },
        {
          title: '数字化供应链',
          tip: '智能周边、电商平台、本地服务',
          imgSrc: require('../../static/images/home/fuwu/huanjing4.png'),
          // size: { w: 107, h: 119 },
        },
        {
          title: '数字化营销',
          tip: '快速对接各类渠道触点营销系统',
          imgSrc: require('../../static/images/home/fuwu/huanjing5.png'),
          // size: { w: 119, h: 119 },
        },
        {
          title: '支付服务',
          tip: '数据准确、实时透明、多通道支付',
          imgSrc: require('../../static/images/home/fuwu/huanjing6.png'),
          // size: { w: 125, h: 95 },
        },
      ],
      shujuyuanList: [
        {
          title: '行为数据源',
          tips: '赋能数字时代',
          tips2: '助力客户实现价值共赢',
          img: require('../../static/images/newretail/shujuyuan/wuda1.png'),
          size: { w: 85, h: 82 },
        },
        {
          title: '行为数据源',
          tips: '全业务场景布局',
          tips2: '助力企业进行数字化转型',
          img: require('../../static/images/newretail/shujuyuan/wuda2.png'),
          size: { w: 85, h: 82 },
        },
        {
          title: '商业经营数据来源',
          tips: '深厚的行业积累',
          tips2: '助力客户加速创新',
          img: require('../../static/images/newretail/shujuyuan/wuda3.png'),
          size: { w: 78, h: 78 },
        },
        {
          title: '技术运维数据源',
          tips: '卓越的安全与质量管理',
          tips2: '助力客户品质升级',
          img: require('../../static/images/newretail/shujuyuan/wuda4.png'),
          size: { w: 81, h: 86 },
        },
        {
          title: '第三方数据源',
          tips: '中国首家企业价值链优化',
          tips2: '及管理运营服务平台',
          img: require('../../static/images/newretail/shujuyuan/wuda5.png'),
          size: { w: 75, h: 72 },
        },
      ],
      //了解便利电
      liaojieList: [
        {
          title: '2016',
          tips: '便利电成立',
        },
        {
          title: '331+',
          tips: '覆盖城市',
        },
        {
          title: '200+',
          tips: '战略合作伙伴',
        },
        {
          title: '2000000+',
          tips: '服务用户',
        },
      ],
      //企业文化
      wenhuaList: [
        {
          title: '企业愿景',
          tips: '垂直行业服务智能化的领军企业',
          img: require('../../static/images/aboutus/wenhua/sm.png'),
          size: { w: 156, h: 161 },
          bgcolor: '#4285F4',
        },
        {
          title: '企业使命',
          tips: '让企业服务进入智能时代',
          img: require('../../static/images/aboutus/wenhua/jzg.png'),
          size: { w: 125, h: 161 },
          bgcolor: '#34A852',
        },
        {
          title: '企业文化',
          tips: '诚信正直、学习创新、协作分享、服务客户',
          img: require('../../static/images/aboutus/wenhua/jyln.png'),
          size: { w: 152, h: 134 },
          bgcolor: '#EA4336',
        },
      ],
      //地址
      center1: { lng: 123.463744, lat: 41.727942 },
      center2: { lng: 116.575978, lat: 39.789776 },
      // 输入数据
      address: [],
      name: '',
      phone: '',
      textareaText: '',
      //留言ajax
      submitting: false,
    }
  }
  //周期周期周期周期周期周期周期周期周期周期周期周期周期周期周期周期周期周期周期周期周期周期周期周期周期周期周期周期
  componentDidMount() {
    window.scrollTo(0, 0)
  }
  //方法方法方法方法方法方法方法方法方法方法方法方法方法方法方法方法方法方法方法方法方法方法方法方法方法方法方法
  //输入绑定
  cascaderChange = (value, selectedOptions) => {
    //value是个数组["天津"]
    // console.log(value, selectedOptions)
    this.setState({
      address: value,
    })
  }
  inputChange = (e) => {
    // console.log(e.target.value)
    let ele = e.target
    let key = ele.getAttribute('data-name')
    let value = ele.value
    this.setState({
      [key]: value,
    })
  }
  //提交
  submit = () => {
    // console.log(this.state.address, this.state.name, this.state.phone, this.state.textareaText)
    let address = this.state.address.join(',')
    let name = this.state.name
    let phone = this.state.phone
    let textareaText = this.state.textareaText
    if (address === '' || name === '' || phone === '' || textareaText === '') {
      message.warning('留言信息请填写完整')
      return
    }

    //ajax
    this.setState({ submitting: true })
    setTimeout(() => {
      message.success('留言成功')
      this.setState({
        address: [],
        name: '',
        phone: '',
        textareaText: '',
        submitting: false,
      })
    }, 900)
    // //请求
    // axios.post('/user', {
    //   firstName: 'Fred',
    //   lastName: 'Flintstone'
    // })
    //   .then(function (response) {
    //     console.log(response);
    //   })
    //   .catch(function (error) {
    //     console.log(error);
    //   });
  }
  //渲染渲染渲染渲染渲染渲染渲染渲染渲染渲染渲染渲染渲染渲染渲染渲染渲染渲染渲染渲染渲染渲染渲染渲染渲染渲染渲染渲染渲染渲染渲染
  render() {
    const {
      liaojieList, //了解便利电
      wenhuaList, //企业文化
      submitting,
      shujuyuanList,
      fuwuList,
    } = this.state

    // 服务
    let fuwuDiv = fuwuList.map((obj, index) => (
      <div key={index} style={{ width: '332px' }}>
        <QueueAnim type={['right', 'left']} leaveReverse={true} forcedReplay={true} delay={10 + index * 50}>
          <div key={index} className="flexColumn" style={{ padding: '45px 0' }}>
            <div className="flexCenter flexJCenter" style={{ width: '294px', height: '222px', marginBottom: '12px' }}>
              <img
                src={obj.imgSrc}
                alt="辽宁便利电科技"
                style={{
                  width: '100%',
                  height: '100%',
                }}
              />
            </div>
            {/* <div style={{ fontSize: "14px", color: "#000" }}>{obj.title}</div> */}
            {/* <div style={{ fontSize: "12px", color: "#4A4A4A" }}>{obj.tip}</div> */}
          </div>
        </QueueAnim>
      </div>
    ))
    //企业大数据源
    let shujuyuan = shujuyuanList.map((obj, index) => (
      // <Col key={index} span={4.5}>
      <div style={{ flex: 1 }}>
        <QueueAnim type={['bottom', 'top']} leaveReverse={true} forcedReplay={true} delay={10 + index * 100}>
          <div key={index} className="flexColumn flexJCenter" style={{ margin: '0 auto' }}>
            <div style={{ width: '48px', height: '48px', marginTop: 20 }}>
              <img
                src={obj.img}
                alt="辽宁便利电科技"
                style={{
                  width: obj.size.w > obj.size.h ? '100%' : 'auto',
                  height: obj.size.w > obj.size.h ? 'auto' : '100%',
                }}
              />
            </div>
            <div
              style={{
                fontSize: '17px',
                color: '#fff',
                marginTop: '40px',
                marginBottom: '5px',
              }}
            >
              {/* {obj.title} */}
            </div>
            <div style={{ fontSize: '12px', color: '#fff' }}>{obj.tips}</div>
            <div style={{ fontSize: '12px', color: '#fff' }}>{obj.tips2}</div>
          </div>
        </QueueAnim>
        {/* </Col> */}
      </div>
    ))
    //了解便利电
    let liaojie = liaojieList.map((obj, index) => (
      <Col key={index} span={6}>
        <div
          key={index}
          className="flexColumn"
          style={{
            height: '180px',
            boxSizing: 'border-box',
            borderLeft: index.toString() === '0' ? 'none' : '1px solid #D7D6D6',
            justifyContent: 'center',
          }}
        >
          <QueueAnim type={index % 2 === 0 ? ['top', 'bottom'] : ['bottom', 'top']} leaveReverse={true} forcedReplay={true} delay={10}>
            <div key={index} className="flexColumn">
              <div
                style={{
                  fontSize: '42px',
                  color: '#000',
                  fontWeight: 'bolder',
                }}
              >
                {obj.title}
              </div>
              <div style={{ fontSize: '17px' }}>{obj.tips}</div>
            </div>
          </QueueAnim>
        </div>
      </Col>
    ))
    //企业文化
    let wenhua = wenhuaList.map((obj, index) => (
      <div key={index}>
        <QueueAnim type={index % 2 === 0 ? ['top', 'bottom'] : ['bottom', 'top']} leaveReverse={true} forcedReplay={true} delay={100}>
          <div
            key={index}
            className="flexColumn flexJCenter"
            style={{
              width: '332px',
              height: '236px',
              backgroundColor: obj.bgcolor,
            }}
          >
            <div className="flexCenter flexJCenter" style={{ width: '70px', height: '70px', marginBottom: '20px' }}>
              <img
                src={obj.img}
                alt="辽宁便利电科技"
                style={{
                  width: obj.size.w > obj.size.h ? '100%' : 'auto',
                  height: obj.size.w > obj.size.h ? 'auto' : '100%',
                }}
              />
            </div>
            <div style={{ fontSize: '17px', color: '#fff' }}>{obj.title}</div>
            <div style={{ fontSize: '12px', color: '#fff' }}>{obj.tips}</div>
          </div>
        </QueueAnim>
      </div>
    ))
    //domdomdomdomdomdomdomdomdomdomdomdomdomdomdomdomdomdomdomdomdomdomdomdomdomdomdomdomdomdomdomdomdomdomdom
    return (
      <div className="aboutus_page">
        <PageBg
          bgSrc={require('../../static/images/contactUs_bg.png')}
          titleM="公司就是一个产品"
          descp="产业互联网时代，互联网产品只有一个"
          // isFan={true}
        />

        {/* 了解便利电 */}
        <div className="liaojie overhidden common_item_wrap" style={{ backgroundColor: '#fff' }}>
          <div className="flexColumn aboutus_titlewrap">
            <div className="aboutus_titlewrap_title" style={{ marginBottom: '20px' }}>
              了解便利电
            </div>
          </div>
          {window.screen.width > mobileW ? (
            <div style={{ height: '180px' }}>
              <ScrollOverPack playScale={[0.15, 0.15]}>
                <Row type="flex" className="common_wrap">
                  {liaojie}
                </Row>
              </ScrollOverPack>
            </div>
          ) : (
            <div style={{ height: '180px' }}>
              <Row type="flex" className="common_wrap">
                {liaojie}
              </Row>
            </div>
          )}
          <div
            className="common_wrap"
            style={{
              fontSize: '14px',
              marginTop: '30px',
              marginBottom: '60px',
            }}
          >
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;便利电科技集团成立于2016年，是一家基于先进的云计算架构、将管理变革创造成为一种平台化云服务的科技公司，以技术创新、模式创新、应用创新为驱动，
            致力于为企业提供基于S2b模式的产业互联网转型升级解决方案，方案涵盖营销类、交付类和结算类全业务场景。
            <p>
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;作为一家科技创新企业，拥有强大且高效的技术研发和运营服务能力，是国家AAA级信用企业、高新技术企业、双软认证企业，拥有ISO9001 质量管理体系认
              证以及多项自主研发的知识产权和专利。
            </p>
            <p>
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;合作伙伴涵盖世界500强、国内知名企业和各行业客户。现有客户包括:中国移动，中国联通，中国电信、京东零售、京东物流、上海医药、爱奇艺、中国人保、格力电器、OPPO、淘车网、芒果地产、仁和保险等100多家企业，并为200余万名自由职业者提供了共享经济服务。
            </p>
          </div>
        </div>

        {/* 企业文化 */}
        <div className="wenhua overhidden common_item_wrap" style={{ backgroundColor: '#F9F9F9' }}>
          <div className="flexColumn aboutus_titlewrap">
            <div className="aboutus_titlewrap_title">企业文化</div>
          </div>
          {window.screen.width > mobileW ? (
            <div
              style={{
                height: '270px',
                marginTop: '25px',
                marginBottom: '60px',
              }}
            >
              <ScrollOverPack playScale={[0.15, 0.15]}>
                <Row type="flex" justify="space-between" className="common_wrap">
                  {wenhua}
                </Row>
              </ScrollOverPack>
            </div>
          ) : (
            <div
              style={{
                height: '270px',
                marginTop: '25px',
                marginBottom: '60px',
              }}
            >
              <Row type="flex" justify="space-between" className="common_wrap">
                {wenhua}
              </Row>
            </div>
          )}
        </div>
        {/*  五大核心优势助力提升客户价值 */}
        <div
          className="shujuyuan overhidden common_item_wrap womenwuda"
          style={{
            backgroundColor: '#fff',
            paddingTop: '20px',
            paddingBottom: '90px',
          }}
        >
          <div className="flexColumn newretail_titlewrap" style={{ marginBottom: '50px' }}>
            <div className="newretail_titlewrap_title">五大核心优势助力提升客户价值</div>
            {/* <div className="newretail_titlewrap_descp">
              企业数据源分用户行为、经营管理、技术运维、第三方四个维度
            </div> */}
          </div>
          {window.screen.width > mobileW ? (
            <div style={{ height: '158px' }}>
              <ScrollOverPack playScale={[0.15, 0.15]}>
                {/* <Row type="flex" className="common_wrap"> */}
                {/* </Row> */}
                <div style={{ display: 'flex' }} className="common_wrap">
                  {shujuyuan}
                </div>
              </ScrollOverPack>
            </div>
          ) : (
            <div style={{ height: '158px' }}>
              {/* <Row type="flex" className="common_wrap">
                {shujuyuan}
              </Row> */}
              <div style={{ display: 'flex' }} className="common_wrap">
                {shujuyuan}
              </div>
            </div>
          )}
        </div>
        {/* 服务 */}
        <div className="fuwu overhidden common_item_wrap" style={{ backgroundColor: '#F9F9F9' }}>
          <div className="flexColumn home_titlewrap">
            <div className="home_titlewrap_title" style={{ fontSize: 24, marginTop: 20 }}>
              办公环境
            </div>
            {/* <div className="home_titlewrap_descp">
              针对不同通信行业场景的零售业务需求，为通信客户提供一站式新零售技术服务
            </div> */}
          </div>
          {window.screen.width > mobileW ? (
            <div style={{ height: '630px' }}>
              <ScrollOverPack playScale={[0.15, 0.15]}>
                <Row type="flex" justify="space-between" className="common_wrap">
                  {fuwuDiv}
                </Row>
              </ScrollOverPack>
            </div>
          ) : (
            <div style={{ height: '630px' }}>
              <Row type="flex" justify="space-between" className="common_wrap">
                {fuwuDiv}
              </Row>
            </div>
          )}
        </div>
        {/* 软件著作权 */}
        <div className="fuwu overhidden common_item_wrap" style={{ height: 650 }}>
          <div className="qualification_title" style={{ marginTop: 50, marginBottom: 30 }}>
            期待你成为其中的一员
          </div>
          <div>
            <Carousel3d itemList={this.state.rightList} />
          </div>
        </div>

        {/* 联系我们 */}
        <div className="lianxi overhidden common_item_wrap" style={{ backgroundColor: '#fff' }}>
          <div className="flexColumn aboutus_titlewrap">
            {/* <div className="aboutus_titlewrap_title">便利电分布</div>
            <div className="aboutus_titlewrap_title" style={{ fontSize: 14, marginTop: 10 }}>
              以北京、沈阳为中心，东北地区为支撑，天津、海口、江西为区域中心，逐步辐射全国。
            </div> */}
          </div>

          <div className="common_wrap">
            {/* 沈阳 */}
            {/* <div className="contact_container address">
              <div style={{ width: 600, height: 420, float: 'left' }}>
                <img
                  className="img100"
                  src={require('../../static/images/aboutus/ditu1.png')}
                  alt="辽宁便利电科技"
                />
              </div>
              <div style={{ marginRight: 30 }}>
                <div className="address_descp">
                  <div className="title" style={{ marginBottom: 20 }}>
                    北京总部
                  </div>
                  <div className="address_text">
                    地址：亦庄经济开发区科创十二街鸿坤云时代B2栋1203
                    <p>
                      <span style={{ color: 'rgba(255,255,255,1)' }}>地址：</span>丰台区汉威国际广场四区3#楼506
                    </p>
                  </div>
                  <div className="phone">电话：400-086-0606</div>
                  <div className="email">邮箱：lnbld@bld365.com</div>
                  <div className="zipcode">邮编：100000</div>
                </div>
                <div style={{ width: 426, borderTop: '.5px solid #000', height: 0.5, marginLeft: 35, marginTop: 20 }} />
                <div className="address_descp">
                  <div className="title" style={{ marginBottom: 20, marginTop: 20 }}>
                    沈阳总部
                  </div>
                  <div className="address_text">地址：沈阳市浑南区金辉街1号德宝大厦702/809</div>
                  <div className="phone">电话：024-22806888/22817888</div>
                  <div className="email">邮箱：lnbld@bld365.com</div>
                  <div className="zipcode">邮编：110000</div>
                </div>
              </div>
            </div> */}
            {/* 北京 */}
            {/* <div className="contact_container address" style={{borderTop:0}}>
         
            </div> */}
            {/* 在线留言 */}
            <div className="contact_container msg_container">
              <div
                style={{
                  color: '#4A4A4A',
                  fontSize: '19px',
                  padding: '0 50px',
                }}
              >
                在线留言
              </div>
              <div style={{ padding: '0 85px' }}>
                <div className="flexCenter flexBetween" style={{ margin: '25px 0 20px' }}>
                  {/* <AutoComplete dataSource={dataSource} placeholder='省份' /> */}
                  <Cascader options={Position} changeOnSelect placeholder="省份" value={this.state.address} onChange={this.cascaderChange} disabled={this.state.submitting} />
                  <Input
                    placeholder="姓名"
                    value={this.state.name}
                    onChange={(e) => {
                      this.inputChange(e)
                    }}
                    data-name="name"
                    disabled={this.state.submitting}
                  />
                  <Input
                    placeholder="电话"
                    value={this.state.phone}
                    onChange={(e) => {
                      this.inputChange(e)
                    }}
                    data-name="phone"
                    disabled={this.state.submitting}
                  />
                </div>
                <TextArea
                  placeholder="请输入留言内容"
                  value={this.state.textareaText}
                  onChange={(e) => {
                    this.inputChange(e)
                  }}
                  data-name="textareaText"
                  disabled={this.state.submitting}
                />
              </div>
              <Button onClick={this.submit} loading={submitting} style={{ fontSize: '14px' }}>
                提交
              </Button>
            </div>
          </div>
        </div>
      </div>
    )
  }
}
