/* eslint-disable */
import { Button } from 'antd'
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { adActions } from '../../redux/models/ad'
import { NavLink, withRouter } from 'react-router-dom'
import { Modal, message, Input } from 'antd'
import PageBg from '../../components/PageBg/PageBg'
import requestw from '../../utils/requestw'
import './jianliu.less'
import $ from 'jquery'
const { TextArea } = Input

let gundongTimer = null
const transitionTime = 0.2 //单位s

export const fuwuList1 = [
  {
    title: '公文管理',
    tip: '手机办公实现医院公文想批就批。',
    imgSrc: require('../../static/images/hangyeyingyong/通用解决方案/公文管理.png'),
    url: '/Document',
  },
  {
    title: '数智化CRM系统',
    tip: '基于简流低代码平台开发，打造符合企业数智化需求的CRM客户管理系统。',
    imgSrc: require('../../static/images/hangyeyingyong/通用解决方案/数字化CR系统.png'),
    url: '/digitization',
  },
  {
    title: '费控报销',
    tip: '以费用预算和报销管理为核心，包含费用类别设置、费用预算编审、费用预算调整、费用报销、差旅费报销、付款等，让您的财务管理工作变得更简单。',
    imgSrc: require('../../static/images/hangyeyingyong/通用解决方案/费控报销.png'),
    url: '/costcontrol',
  },
  {
    title: '合同管理',
    tip: '合同管理是面向中小企业的合同管理软件，覆盖企业合同管理工作中的最基础应用，包括合同信息，合同收款，合同付款和合同开票。',
    imgSrc: require('../../static/images/hangyeyingyong/通用解决方案/合同管理.png'),
    url: '/contract',
  },
  {
    title: '二维码扫码评价系统',
    tip: '将医务人员的工作态度与服务质量通过不记名的方式直接进行扫码评价，实现评价的公平公正。',
    imgSrc: require('../../static/images/hangyeyingyong/通用解决方案/二维码扫码评价系统.png'),
    url: '/QRcode',
  },
  {
    title: '数字化进销存',
    tip: '分考虑企业的采购、销售、库存和管理需求,实现数字企业资源信息的有效整合，提高企业的管理水平及行业竞争力。',
    imgSrc: require('../../static/images/hangyeyingyong/通用解决方案/数字化进销存.png'),
    url: '/Purchase',
  },
  {
    title: '智慧门店解决方案',
    tip: '通过简流，打造移动端的智慧门店。帮助门店人员提高工作效率，快速形成门店数据全貌，帮助企业管理者迅速了解门店运营状态，真正实现运营的闭环。',
    imgSrc: require('../../static/images/hangyeyingyong/通用解决方案/智慧门店解决方案.png'),
    url: '/programme',
  },
  {
    title: '工程项目管理',
    tip: '简流赋能，致力于为工程行业打造项目管理全流程解决方案。',
    imgSrc: require('../../static/images/hangyeyingyong/通用解决方案/工程项目管理.png'),
    url: '/engineering',
  },
  {
    title: '服务业项目管理系统',
    tip: '基于简流低代码平台开发，专属于服务行业的项目管理系统全平台系统解决方案。',
    imgSrc: require('../../static/images/hangyeyingyong/通用解决方案/服务业务项目管理系统.png'),
    url: '/serviceIndustry',
  },
  {
    title: '家居门店数字化管理',
    tip: '家居门店以客户为主中心，围绕订单管理、资金管理、送装管理、售后管理全流程的数字化解决方案。',
    imgSrc: require('../../static/images/hangyeyingyong/通用解决方案/家居门店数字化管理.png'),
    url: '/HomeFurnishing',
  },
  {
    title: '供应链协同管理系统',
    tip: '通过简流协同，降低库存，提高订单履行，提高团队工作效率，与供应商安全轻松开展合作。',
    imgSrc: require('../../static/images/hangyeyingyong/通用解决方案/供应链协同管理系统.png'),
    url: '/SupplyChain',
  },
  {
    title: '数字化街道社区工作平台',
    tip: '数字化改革，让街道社区上传下达工作变得更简单、高效、便捷。',
    imgSrc: require('../../static/images/hangyeyingyong/通用解决方案/数字化街道社区工作平台.png'),
    url: '/Community',
  },
]

export const fuwuList2 = [
  {
    title: '金融行业解决方案',
    tip: '提供监管合规金融专有云服务，具备金融级安全合规能力，用科技引领金融变革，共建智慧金融。',
    imgSrc: require('../../static/images/hangyeyingyong/行业解决方案/金融行业解决方案.png'),
    url: '/JinRong',
  },
  {
    title: '教育行业解决方案',
    tip: '协助教育培训企业为客户创建招生、在线选课、在线课程管理、教务管理、人事行政管理及家长服务一于一体的教育培训管理系统，通过数字化的手段让客户……',
    imgSrc: require('../../static/images/hangyeyingyong/行业解决方案/教育行业解决方案.png'),
    url: '/JiaoYu',
  },
  // {
  //   title: '共享经济平台解决方案',
  //   tip: '人工智能赋能，深耕共享经济行业。',
  //   imgSrc: require('../../static/images/hangyeyingyong/行业解决方案/共享经济平台解决方案.png'),
  //   url: '/GongXiang',
  // },
  {
    title: '直播行业解决方案',
    tip: '全新的一站式“多路音视频互动”解决方案，主打直播连麦和多画面特效，通过移动直播 SDK 打造跨平台一对多、多对多的超清酷炫直播场景。',
    imgSrc: require('../../static/images/hangyeyingyong/行业解决方案/直播行业解决方案.png'),
    url: '/ZhiBo',
  },
  {
    title: '短视频行业解决方案',
    tip: '短视频解决方案是针对泛娱乐、媒体、UGC等领域的一站式短视频服务方案。让您快速拥有美颜、滤镜、AR等丰富采编能力。',
    imgSrc: require('../../static/images/hangyeyingyong/行业解决方案/短视频行业解决方案.png'),
    url: '/Duan',
  },
  {
    title: '咨询行业解决方案',
    tip: '提供一站式在线联络平台解决方案，包括音视频通话、在线会话、电话等服务，可应用于在线问诊、远程心理咨询、在线政务服务、在线教育等场景。',
    imgSrc: require('../../static/images/hangyeyingyong/行业解决方案/咨询行业解决方案.png'),
    url: '/ZiXun',
  },
  {
    title: '直销行业解决方案',
    tip: '直销作为一种集中管理，高可靠性要求，高安全性要求的行业，网络的连通率和安全性成为方案的关键环节，直接影响到客户的体验感。',
    imgSrc: require('../../static/images/hangyeyingyong/行业解决方案/直销行业解决方案.png'),
    url: '/ZhiXiao',
  },
  {
    title: '房地产行业解决方案',
    tip: '地产物业行业员工多且分散，业态复杂，用工形式多样、工时制度多样，集中化统一化管理难度大。这样的情况下需要一套强大的管理软件帮助企业管理……',
    imgSrc: require('../../static/images/hangyeyingyong/行业解决方案/房地产行业解决方案.png'),
    url: '/FangDiChan',
  },
  {
    title: '社交电商解决方案',
    tip: '社交电商系统是建立在社区概念基础上的，并结合分销卖货电商的微商模式， 开发的一款集社交、社群的社区商城系统。',
    imgSrc: require('../../static/images/hangyeyingyong/行业解决方案/社交电商解决方案.png'),
    url: '/SheJiao',
  },
]

export function FuwuItem(props) {
  const { item } = props
  const width = 328
  return (
    <NavLink to={item.url} onClick={props.emitClick} style={{ margin: '0 10px' }}>
      <div style={{ width, margin: '12px 0' }}>
        <div>
          <div style={{ width, height: 190 }}>
            <img
              src={item.imgSrc}
              alt="辽宁便利电科技"
              style={{
                width: '100%',
                height: '100%',
                objectFit: 'cover',
              }}
            />
          </div>
          <div style={{ backgroundColor: '#fff', overflow: 'hidden', borderRadius: '0 0 7px 7px' }}>
            <div style={{ fontSize: '18px', color: '#262626', textAlign: 'left', marginLeft: 21, marginTop: 17 }}>{item.title}</div>
            <div style={{ fontSize: '13px', height: 83, color: '#8c8c8c', textAlign: 'left', margin: '7px 21px 0' }}>{item.tip}</div>
          </div>
        </div>
      </div>
    </NavLink>
  )
}

class JianLiu extends Component {
  constructor(props) {
    super(props)
    this.state = {
      // 走马灯
      carouselList: [
        {
          imgBg: require('../../static/images/carousel_bg.png'),
        },
      ],
      //大图和文字item
      bigImgTextList: [
        {
          title: '售前+物流+售后',
          descp: '一体化服务中台',
          imgSrc: require('../../static/images/home/bigImgText/home01.png'),
        },
        {
          title: '全场景运营指导',
          descp: '持续生意产品运营',
          imgSrc: require('../../static/images/home/bigImgText/home02.png'),
        },
        {
          title: '搭建众包服务平台',
          descp: '共享经济灵活用工新模式',
          imgSrc: require('../../static/images/home/bigImgText/home03.png'),
        },
      ],
      //合作伙伴
      partnerList: [
        //真实
        {
          imgSrc: require('../../static/images/home/partner/partner_JD.png'), //京东
          imgW: '114px',
          imgH: '23px',
        },
        {
          imgSrc: require('../../static/images/home/partner/partner_dianxin.png'), //电信
          imgW: '93px',
          imgH: '28px',
        },
        {
          imgSrc: require('../../static/images/home/partner/partner_yidong.png'), //移动
          imgW: '84px',
          imgH: '27px',
        },
        {
          imgSrc: require('../../static/images/home/partner/partner_liantong.png'), //联通
          imgW: '67px',
          imgH: '37px',
        },
        {
          imgSrc: require('../../static/images/home/partner/partner_wuliu.png'), //京东物流
          imgW: '115px',
          imgH: '27px',
        },
        {
          imgSrc: require('../../static/images/home/partner/partner_pinganyinhang.png'), //平安银行
          imgW: '93',
          imgH: '32px',
        },
        {
          imgSrc: require('../../static/images/home/partner/partner_zhaoshangyinhang.png'), //招商银行
          imgW: '101px',
          imgH: '24px',
        },
        {
          imgSrc: require('../../static/images/home/partner/partner_zhongguoyouzheng.png'), //中国邮政
          imgW: '101px',
          imgH: '24px',
        },
        {
          imgSrc: require('../../static/images/home/partner/partner_zhongguoguangdayinhang.png'), //中国光大银行
          imgW: '156px',
          imgH: '41px',
        },
        {
          imgSrc: require('../../static/images/home/partner/partner_shengjingyinhang.png'), //盛京银行
          imgW: '103px',
          imgH: '27px',
        },
        {
          imgSrc: require('../../static/images/home/partner/partner_xiaomi.png'), //小米
          imgW: '81px',
          imgH: '34px',
        },
        {
          imgSrc: require('../../static/images/home/partner/partner_meituan.png'), //美团
          imgW: '101px',
          imgH: '37px',
        },
        {
          imgSrc: require('../../static/images/home/partner/partner_aiqiyi.png'), //爱奇艺
          imgW: '103px',
          imgH: '35px',
        },
        {
          imgSrc: require('../../static/images/home/partner/partner_shunfengsuyun.png'), //顺丰速运
          imgW: '103px',
          imgH: '38px',
        },
        {
          imgSrc: require('../../static/images/home/partner/partner_etc.png'), //ETC
          imgW: '103px',
          imgH: '24px',
        },
        //真实 end
      ],
      homeitemImg: [
        { imgBg: require('../../static/images/homeItem/homeitem1.png'), toUrl: '/programme' },
        { imgBg: require('../../static/images/homeItem/homeitem2.png'), toUrl: '/engineering' },
        { imgBg: require('../../static/images/homeItem/homeitem3.png'), toUrl: '/digitization' },
        { imgBg: require('../../static/images/homeItem/homeitem4.png'), toUrl: '/Purchase' },
        { imgBg: require('../../static/images/homeItem/homeitem5.png'), toUrl: '/costcontrol' },
        { imgBg: require('../../static/images/homeItem/homeitem6.png'), toUrl: '/contract' },
        { imgBg: require('../../static/images/homeItem/homeitem7.png'), toUrl: '/serviceIndustry' },
        { imgBg: require('../../static/images/homeItem/homeitem8.png'), toUrl: '/QRcode' },
        { imgBg: require('../../static/images/homeItem/homeitem9.png'), toUrl: '/SupplyChain' },
        { imgBg: require('../../static/images/homeItem/homeitem10.png'), toUrl: '/HomeFurnishing' },
        { imgBg: require('../../static/images/homeItem/homeitem11.png'), toUrl: '/Document' },
        { imgBg: require('../../static/images/homeItem/homeitem12.png'), toUrl: '/Community' },
      ],
      itemw: 0,
      partnerIndex: 0,
      modal: false,
      isloading: false,
      modaltitle: '',
      tabActiveIndex: 0,
      isH5: false,
    }
  }
  //周期周期周期周期周期周期周期周期周期周期周期周期周期周期周期周期周期周期周期周期周期周期周期周期周期周期周期周期
  componentWillMount() {
    localStorage.setItem('bldweb', '')
  }
  componentDidMount() {
    window.scrollTo(0, 0)
    this.runJq()
  }
  runJq() {
    $(function () {
      //导航菜单
      var ytx = {}
      $('[t_nav]').hover(
        function () {
          var _nav = $(this).attr('t_nav')
          clearTimeout(ytx[_nav + '_timer'])
          ytx[_nav + '_timer'] = setTimeout(function () {
            $('#' + _nav)
              .stop(true, true)
              .slideDown(200)
          }, 150)
        },
        function () {
          var _nav = $(this).attr('t_nav')
          clearTimeout(ytx[_nav + '_timer'])
          ytx[_nav + '_timer'] = setTimeout(function () {
            $('#' + _nav)
              .stop(true, true)
              .slideUp(200)
          }, 150)
        }
      )

      //导航绿色标题高度处理
      $('.submenu dl').each(function () {
        var dl_h = $(this).height()
        $(this).find('dt').height(dl_h)
      })

      //首页短信、im、语音、视频动画效果
      $('.box1 ul li').hover(
        function () {
          $(this).find('.unhover').find('i').animate({ bottom: -145, opacity: '0' }, 500)
          $(this).find('.unhover').find('.txt').animate({ left: -125, opacity: '0' }, 500)
          $(this).find('.hover').show().find('i').animate({ top: 0 }, 500)
          $(this).find('.hover').show().find('.txt').animate({ right: 0 }, 500)
        },
        function () {
          $(this).find('.unhover').find('i').animate({ bottom: 0, opacity: '1' }, 500)
          $(this).find('.unhover').find('.txt').animate({ left: 0, opacity: '1' }, 500)
          $(this).find('.hover').find('i').animate({ top: -125 }, 500)
          $(this).find('.hover').find('.txt').animate({ right: -110 }, 500)
        }
      )

      //首页“简单”、“可靠”、“专注”、“全球”动画效果
      var current = $('.index_2 span.txt.current').index()
      $('.index_2 span.txt')
        .not('.current')
        .hover(
          function () {
            var span_index = $(this).index()
            $(this).addClass('current')
            $('.txt_desc').find('span').eq(span_index).fadeIn(800).siblings('span').fadeOut(800)
          },
          function () {
            var span_index = $(this).index()
            $(this).removeClass('current')
            $('.txt_desc').find('span').eq(span_index).fadeOut(800).siblings('span').eq(current).fadeIn(800)
          }
        )

      //首页客户图标鼠标放上去状态变化
      $('.index_4 ul li').hover(
        function () {
          var img_src = $(this).find('img').attr('src')
          var img_name = img_src.substring(7).replace('.png', '')
          //alert(img_name);
          $(this)
            .find('img')
            .attr('src', '/front/' + img_name + '_hover.png')
        },
        function () {
          var img_src = $(this).find('img').attr('src')
          var img_name = img_src.substring(7).replace('_hover.png', '')
          $(this)
            .find('img')
            .attr('src', '/front/' + img_name + '.png')
        }
      )

      //语音通知手风琴效果
      $('.voice_2 ul li').each(function () {
        var fold = $(this).find('.fold')
        var unfold = $(this).find('.unfold')
        if (fold.is(':hidden')) {
          $(this).width(445)
        } else {
          $(this).width(200)
        }
      })

      $('.voice_2 ul li').click(function () {
        $(this).animate({ width: 445 }, 200)
        $(this).find('.unfold').show()
        $(this).find('.fold').hide()
        $(this).siblings().animate({ width: 200 }, 200)
        $(this).siblings().find('.unfold').hide()
        $(this).siblings().find('.fold').show()
      })

      //下拉框处理
      $('div.select_box ul li:even').css('background', '#f5f5f5')

      $('div.select_box').click(function (e) {
        if ('readonly' == $(this).attr('readonly')) {
          return false
        }
        e.stopPropagation()
        $(this).children('ul').toggle()
        $(this).toggleClass('focus')
      })
    })
  }
  openModal = (name) => {
    console.log(name) //1 -2
    this.setState({
      modal: true,
      modaltitle: name,
    })
  }
  //方法方法方法方法方法方法方法方法方法方法方法方法方法方法方法方法方法方法方法方法方法
  //初始化合作伙伴滚动
  initPartnerGundong = () => {
    let { partnerList } = this.state
    let scrollWrap = document.getElementsByClassName('scrollWrap')[0]

    let wrapw = scrollWrap.clientWidth
    let itemw = wrapw / partnerList.length
    this.setState({ itemw })

    this.beginGundong()
  }
  //开始滚动
  beginGundong = () => {
    let scrollWrap = document.getElementsByClassName('scrollWrap')[0]
    gundongTimer = setInterval(() => {
      let { partnerIndex } = this.state
      scrollWrap.style.transition = `all ${transitionTime}s ease-in-out`
      this.setState(
        {
          partnerIndex: partnerIndex - 1,
        },
        () => {
          setTimeout(() => {
            let { partnerIndex } = this.state
            if (partnerIndex <= -8) {
              scrollWrap.style.transition = 'none'
              this.setState({ partnerIndex: 0 })
            }
          }, transitionTime * 1000) //与transition的时间相同
        }
      )
    }, 2000)
  }
  getAdd = () => {
    console.log(this)
    let name = this.refs.name.state.value
    let phone = this.refs.phone.state.value
    let type = document.getElementById('types').value
    this.setState({ isloading: true })
    if (name !== undefined && name !== '') {
      if (phone !== undefined && phone !== '') {
        setTimeout(async () => {
          this.setState({ isloading: false })
          let res = await requestw({
            url: 'https://lubanweb.bld365.com/api/home/fillIn',
            data: {
              userName: name,
              userType: this.state.modaltitle == '我是劳动者' ? 'PERSON' : 'COMPANY',
              // userType:'INSTALL',
              // userPosition:teamName,
              phoneNumber: phone,
              remark: type,
            },
          })
          if (res.code == '0') {
            message.success('提交成功')
            this.setState({
              modal: false,
            })
          } else {
            message.warning(res.data ? res.data : '提交失败')
          }
        }, 1000)
      } else {
        setTimeout(() => {
          message.error('请输入职位名称')
          this.setState({ isloading: false })
        }, 1000)
      }
    } else {
      setTimeout(() => {
        message.error('请输入姓名')
        this.setState({ isloading: false })
      }, 1000)
    }
  }

  handleCancel = () => {
    this.setState({
      modal: false,
    })
  }
  //停止滚动
  stopGundong = () => {
    window.clearInterval(gundongTimer)
    gundongTimer = null
  }

  clickTab = (idx) => {
    const { dispatch } = this.props
    dispatch(adActions.changeTabIndex(2))
  }

  tojianliuitme = () => {
    const { dispatch } = this.props
    dispatch(adActions.changeTabIndex(2))
  }
  handleTabClick(tabActiveIndex) {
    this.setState({
      tabActiveIndex,
    })
  }
  //渲染渲染渲染渲染渲染渲染渲染渲染渲染渲染渲染渲染渲染渲染渲染渲染渲染渲染渲染渲染渲染渲染渲染渲染渲染渲染渲染渲染渲染
  render() {
    const { tabActiveIndex } = this.state

    let fuwuDiv1 = fuwuList1.map((obj, ind) => <FuwuItem key={ind} item={obj} emitClick={this.tojianliuitme} />)
    let fuwuDiv2 = fuwuList2.map((obj, ind) => <FuwuItem key={ind} item={obj} emitClick={this.tojianliuitme} />)
    //domdomdomdomdomdomdomdomdomdomdomdomdomdomdomdomdomdomdomdomdomdomdomdomdomdomdomdomdomdomdomdomdomdomdomdomdomdomdomdomdomdomdomdom
    return (
      <div className="home_page">
        <div style={{ position: 'relative', minWidth: '1080px' }}></div>
        <PageBg
          bgSrc={require('../../static/images/newretail/newretail_bg.png')}
          titleM="简流行业解决方案"
          descp="根据您的需求提供灵活、高效的贴身定制服务"
          contentStyle={{
            textAlign: 'center',
          }}
        />
        <div className="datu overhidden common_item_wrap">
          <div className="m-sys-wrap">
            <div className="m-sys-inner">
              <div className="m-sys-header">
                <ul className="m-sys-tab-wrap">
                  <li className={'m-sys-tab ' + (tabActiveIndex === 0 ? 'active' : '')} style={{ marginRight: 10 }} onClick={this.handleTabClick.bind(this, 0)}>
                    <span className="m-sys-tab-text">通用解决方案</span>
                  </li>
                  <li className={'m-sys-tab ' + (tabActiveIndex === 1 ? 'active' : '')} onClick={this.handleTabClick.bind(this, 1)}>
                    <span className="m-sys-tab-text">行业解决方案</span>
                  </li>
                </ul>
              </div>
              <div className="m-sys-content" style={{ paddingTop: 5 }}>
                <div className={'m-sys-view ' + (tabActiveIndex === 0 ? 'active' : '')}>
                  <>{fuwuDiv1}</>
                </div>
                <div className={'m-sys-view ' + (tabActiveIndex === 1 ? 'active' : '')}>
                  <>{fuwuDiv2}</>
                </div>
              </div>
            </div>
          </div>
        </div>

        <Modal
          title={this.state.modaltitle}
          visible={this.state.modal}
          onOk={this.getAdd}
          onCancel={this.handleCancel}
          okText="确定"
          destroyOnClose
          cancelText="取消"
          footer={[
            <Button key="back" onClick={this.handleCancel}>
              取消
            </Button>,
            <Button key="submit" type="primary" onClick={this.getAdd}>
              确定
            </Button>,
          ]}
        >
          <div className="zhenghe overhidden common_item_wrap" style={{ backgroundColor: '#ffffff' }}>
            <div className="flexColumn newretail_titlewrap" style={{ marginBottom: '35px' }}>
              <div className="newretail_titlewrap_descp">留下您的姓名、联系方式和业务需求，我们的客服人员会尽快联系您</div>
            </div>
            <div
              className="common_wrap  flexBetween"
              style={{
                margin: '76px auto',
                height: '118px',
                width: '100%',
              }}
            >
              <div
                style={{
                  width: '97%',
                  height: '38px',
                  marginRight: '32px',
                  marginBottom: '30px',
                }}
              >
                <Input ref="name" placeholder="请输入您的姓名" />
              </div>
              <div
                style={{
                  width: '97%',
                  height: '38px',
                  marginBottom: '30px',
                }}
              >
                <Input ref="phone" placeholder="请输入您的联系电话" />
              </div>
              <div
                style={{
                  width: '97%',
                  height: '88px',
                  float: 'left',
                  marginBottom: '30px',
                }}
              >
                <TextArea ref="type" id="types" style={{ height: 90, maxHeight: 90 }} placeholder="请输入留言内容" />
              </div>
            </div>
          </div>
        </Modal>
      </div>
    )
  }
}

const mapStateToProps = (state) => ({
  //选择注入哪些state，state 所有的state(reducer)
  ad: state.ad,
})

export default connect(mapStateToProps)(withRouter(JianLiu))
