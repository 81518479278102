/* eslint-disable */
import React, { Component } from 'react'
import $ from 'jquery'
import { Row, Col, Input, Button, message } from 'antd'
import PageBg from '../../components/PageBg2/PageBg'
import './Digitization.less'
import './base.css'
import requestw from '../../utils/requestw'

export default class Programme extends Component {
  constructor(props) {
    super(props)
    this.state = {
      isloading: false,
    }
  }
  //周期周期周期周期周期周期周期周期周期周期周期周期周期周期周期周期周期周期周期周期周期周期周期周期周期周期周期周期
  componentDidMount() {
    window.scrollTo(0, 0)
    this.runJq()
  }
  runJq() {
    $(function () {
      //导航菜单
      var ytx = {}
      $('[t_nav]').hover(
        function () {
          var _nav = $(this).attr('t_nav')
          clearTimeout(ytx[_nav + '_timer'])
          ytx[_nav + '_timer'] = setTimeout(function () {
            $('#' + _nav)
              .stop(true, true)
              .slideDown(200)
          }, 150)
        },
        function () {
          var _nav = $(this).attr('t_nav')
          clearTimeout(ytx[_nav + '_timer'])
          ytx[_nav + '_timer'] = setTimeout(function () {
            $('#' + _nav)
              .stop(true, true)
              .slideUp(200)
          }, 150)
        }
      )

      //导航绿色标题高度处理
      $('.submenu dl').each(function () {
        var dl_h = $(this).height()
        $(this).find('dt').height(dl_h)
      })

      //首页短信、im、语音、视频动画效果
      $('.box1 ul li').hover(
        function () {
          $(this).find('.unhover').find('i').animate({ bottom: -145, opacity: '0' }, 500)
          $(this).find('.unhover').find('.txt').animate({ left: -125, opacity: '0' }, 500)
          $(this).find('.hover').show().find('i').animate({ top: 0 }, 500)
          $(this).find('.hover').show().find('.txt').animate({ right: 0 }, 500)
        },
        function () {
          $(this).find('.unhover').find('i').animate({ bottom: 0, opacity: '1' }, 500)
          $(this).find('.unhover').find('.txt').animate({ left: 0, opacity: '1' }, 500)
          $(this).find('.hover').find('i').animate({ top: -125 }, 500)
          $(this).find('.hover').find('.txt').animate({ right: -110 }, 500)
        }
      )

      //首页“简单”、“可靠”、“专注”、“全球”动画效果
      var current = $('.index_2 span.txt.current').index()
      $('.index_2 span.txt')
        .not('.current')
        .hover(
          function () {
            var span_index = $(this).index()
            $(this).addClass('current')
            $('.txt_desc').find('span').eq(span_index).fadeIn(800).siblings('span').fadeOut(800)
          },
          function () {
            var span_index = $(this).index()
            $(this).removeClass('current')
            $('.txt_desc').find('span').eq(span_index).fadeOut(800).siblings('span').eq(current).fadeIn(800)
          }
        )

      //首页客户图标鼠标放上去状态变化
      $('.index_4 ul li').hover(
        function () {
          var img_src = $(this).find('img').attr('src')
          var img_name = img_src.substring(7).replace('.png', '')
          //alert(img_name);
          $(this)
            .find('img')
            .attr('src', '/front/' + img_name + '_hover.png')
        },
        function () {
          var img_src = $(this).find('img').attr('src')
          var img_name = img_src.substring(7).replace('_hover.png', '')
          $(this)
            .find('img')
            .attr('src', '/front/' + img_name + '.png')
        }
      )

      //语音通知手风琴效果
      $('.voice_2 ul li').each(function () {
        var fold = $(this).find('.fold')
        var unfold = $(this).find('.unfold')
        if (fold.is(':hidden')) {
          $(this).width(445)
        } else {
          $(this).width(200)
        }
      })

      $('.voice_2 ul li').click(function () {
        var li_index = $(this).index()
        $(this).animate({ width: 445 }, 200)
        $(this).find('.unfold').show()
        $(this).find('.fold').hide()
        $(this).siblings().animate({ width: 200 }, 200)
        $(this).siblings().find('.unfold').hide()
        $(this).siblings().find('.fold').show()
      })

      //下拉框处理
      $('div.select_box ul li:even').css('background', '#f5f5f5')

      $('div.select_box').click(function (e) {
        if ('readonly' == $(this).attr('readonly')) {
          return false
        }
        e.stopPropagation()
        $(this).children('ul').toggle()
        $(this).toggleClass('focus')
      })
    })
  }
  getAdd() {
    console.log(this)
    let name = this.refs.name.state.value
    let phone = this.refs.phone.state.value
    let teamName = this.refs.teamName.state.value
    let type = document.getElementById('types').value
    // let type = this.refs.type.state.value
    console.log(name, phone, teamName, type)
    this.setState({ isloading: true })
    console.log(type)
    if (name !== undefined && name !== '') {
      if (phone !== undefined && phone !== '') {
        if (teamName !== undefined && teamName !== '') {
          // if (type !== undefined && type !== '') {
          setTimeout(async () => {
            this.setState({ isloading: false })
            let res = await requestw({
              url: 'https://lubanweb.bld365.com/api/home/fillIn',
              data: {
                userName: name,
                //  userType:this.state.modaltitle=='我是劳动者'? 'PERSON' :'COMPANY',
                phoneNumber: phone,
                userType: 'INSTALL',
                userPosition: teamName,
                remark: type,
              },
            })
            if (res.code == '0') {
              message.success('提交成功')
            } else {
              message.warning(res.data ? res.data : '提交失败')
            }
            // message.success('提交成功')
          }, 1000)
          // } else {
          //   setTimeout(() => {
          //     message.success('提交成功')
          //     // message.error('请输入备注')
          //     this.setState({ isloading: false })
          //   }, 1000)
          // }
        } else {
          setTimeout(() => {
            message.error('请输入职位名称')
            this.setState({ isloading: false })
          }, 1000)
        }
      } else {
        setTimeout(() => {
          message.error('请输入联系电话')
          this.setState({ isloading: false })
        }, 1000)
      }
    } else {
      setTimeout(() => {
        message.error('请输入姓名')
        this.setState({ isloading: false })
      }, 1000)
    }
  }
  toipt() {
    window.location.hash = '#abc'
  }
  //渲染渲染渲染渲染渲染渲染渲染渲染渲染渲染渲染渲染渲染渲染渲染渲染渲染渲染渲染渲染渲染渲染渲染渲染渲染渲染渲染渲染渲染渲染渲染
  render() {
    const { isloading } = this.state

    //企业大数据源

    return (
      <div className="newretail_page">
        <PageBg bgSrc={require('../../static/images/home/shuzhihua/shuzhihua1.png')} noBottomText />

        <img style={{ width: '100%' }} src={require('../../static/images/home/shuzhihua/shuzhihua2.png')} />
        <img style={{ width: '100%' }} src={require('../../static/images/home/shuzhihua/shuzhihua3.png')} />
        <img style={{ width: '100%' }} src={require('../../static/images/home/shuzhihua/shuzhihua4.png')} />
        <img style={{ width: '100%' }} src={require('../../static/images/home/shuzhihua/shuzhihua5.png')} />

        <div className="zhenghe overhidden common_item_wrap"></div>
      </div>
    )
  }
}
